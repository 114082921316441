import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  IDispositivo,
  IFilter,
  IListado,
  ILogUplink,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../../auxiliares/helper.service';
import { ListadosService } from '../../../../auxiliares/listados.service';
import { DispositivosService } from '../../dispositivos.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tabla-uplinks',
  templateUrl: './tabla-uplinks.component.html',
  styleUrls: ['./tabla-uplinks.component.scss'],
})
export class TablaUplinksComponent implements OnInit, OnDestroy {
  public loading = true;

  @Input() dispositivo?: IDispositivo;

  // TABLA REPORTES
  public totalCount = 0;
  public dataSource = new MatTableDataSource<ILogUplink>([]);

  public columnas = [
    'fechaCreacion',
    'timestamp',
    'fCnt',
    'puerto',
    'payload',
    'adr',
    'dr',
    'rssi',
    'snr',
  ];
  public nombreColumnas = [
    'Fecha',
    'Timestamp',
    'FCnt',
    'Puerto',
    'Payload',
    'ADR',
    'DR',
    'RSSI',
    'SNR',
  ];
  public nombreTabla = 'tabla-logs-uplink';
  // Query
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
    sort: '-timestamp',
  };

  // LISTADO CONTINUO
  private datos$?: Subscription;

  constructor(
    public helper: HelperService,
    public matDialog: MatDialog,
    private listadosService: ListadosService,
    public service: DispositivosService
  ) {}

  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter): Promise<void> {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    if (this.dispositivo) {
      this.datos$?.unsubscribe();
      const filter: IFilter = {
        deveui: this.dispositivo.deveui,
      };
      this.queryParams.filter = JSON.stringify(filter);
      this.datos$ = this.listadosService
        .subscribe<IListado<ILogUplink>>('logUplinks', this.queryParams)
        .subscribe((data) => {
          this.dataSource.data = data.datos;
          this.totalCount = data.totalCount;
          console.log(
            new Date().toLocaleString(),
            `listado de logUplinks`,
            data
          );
        });
      await this.listadosService.getLastValue('logUplinks', this.queryParams);
    }
  }

  //

  async ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
