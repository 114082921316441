import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  ICliente,
  IDispositivo,
  IFilter,
  IListado,
  ILogUplink,
  IQueryParam,
  ITipoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LogUplinksService } from '../dispositivos.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit {
  public loading = true;

  // TABLA
  public nombreTabla = 'admin-agro-v2-log-uplinks';
  public dataSource = new MatTableDataSource<ILogUplink>([]);
  public totalCount = 0;
  public columnas = [
    'fechaCreacion',
    'timestamp',
    'deviceName',
    'deveui',
    'tipoDispositivo',
    'cliente',
    'fCnt',
    'puerto',
    'payload',
    'adr',
    'dr',
    'rssi',
    'snr',
    'confirmedUplink',
  ];
  public nombreColumnas = [
    'Fecha',
    'Timestamp',
    'Device Name',
    'DevEUI',
    'Tipo Dispositivo',
    'Cliente',
    'FCnt',
    'Puerto',
    'Payload',
    'ADR',
    'DR',
    'RSSI',
    'SNR',
    'Confirmado',
  ];
  // Filtros Tabla
  public search: IRegExpSearch = {
    placeholder: 'DevEUI, Device Name',
    fields: ['deveui', 'deviceName'],
  };
  public tipoDispositivo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'tipoDispositivo',
    },
    label: 'Tipo',
    selectLabel: 'nombre',
    selectValue: 'nombre',
    tipo: 'select',
  };
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public puerto: IFiltroTabla = {
    filter: {
      field: 'puerto',
    },
    label: 'Puerto',
    tipo: 'input',
    inputType: 'number',
  };
  public adr: IFiltroTabla = {
    elementos: [
      { nombre: 'Si', valor: true },
      { nombre: 'No', valor: false },
    ],
    filter: {
      field: 'adr',
    },
    label: 'ADR',
    selectLabel: 'nombre',
    selectValue: 'valor',
    tipo: 'select',
  };
  public dr: IFiltroTabla = {
    filter: {
      field: 'dr',
    },
    label: 'Data Rate',
    tipo: 'input',
    inputType: 'number',
  };
  public timestamp: IFiltroTabla = {
    filter: {
      field: 'timestamp',
    },
    label: 'Timestamp',
    tipo: 'dateRange',
  };
  public deveui: IFiltroTabla = {
    filter: {
      field: 'deveui',
    },
    label: 'Deveui',
    tipo: 'input',
  };
  public filtros: IFiltroTabla[] = [
    this.timestamp,
    this.deveui,
    this.tipoDispositivo,
    this.cliente,
    this.puerto,
    this.adr,
    this.dr,
  ];

  // QUERY+
  private populate = [
    {
      path: 'cliente',
      select: 'nombre admin',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
    sort: '-fechaCreacion',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public logUplinks$?: Subscription;
  public clientes$?: Subscription;
  public tipoDispositivos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: LogUplinksService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  public async eliminar(dato: IDispositivo): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el log ?`
    );

    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }
  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.logUplinks$?.unsubscribe();
    this.logUplinks$ = this.listadosService
      .subscribe<IListado<ILogUplink>>('logUplinks', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de logUplinks`, data);
      });
    await this.listadosService.getLastValue('logUplinks', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarTipoDispositivo(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.tipoDispositivos$?.unsubscribe();
    this.tipoDispositivos$ = this.listadosService
      .subscribe<IListado<ITipoDispositivo>>('tipoDispositivos', query)
      .subscribe((data) => {
        this.tipoDispositivo.elementos = data.datos;
        console.log(`listado de tipodispositivos`, data);
      });
    await this.listadosService.getLastValue('tipoDispositivos', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarClientes(),
      this.listarTipoDispositivo(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.logUplinks$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.tipoDispositivos$?.unsubscribe();
  }
}
