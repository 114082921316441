<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title></mat-card-header
  >

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="loginForm" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Nombre  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Llave  -->
      <mat-form-field>
        <mat-label>Llave</mat-label>
        <input matInput placeholder="Llave" formControlName="key" required />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
