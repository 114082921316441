import { Component, OnInit } from '@angular/core';
import { Gateways2Service } from '../gateways-2.service';
import { IGatewayChirpstackGetAll, IListado } from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/helper.service';
export type GatewayMap = IGatewayChirpstackGetAll & {
  online?: boolean;
  icon?: google.maps.Icon;
};
@Component({
  selector: 'app-gateways',
  templateUrl: './gateways.component.html',
  styleUrls: ['./gateways.component.scss'],
})
export class GatewaysComponent implements OnInit {
  public selected?: GatewayMap;

  public gateways?: IListado<GatewayMap>;
  constructor(private service: Gateways2Service) {}

  private iconGateway(e: boolean): google.maps.Icon {
    return {
      url: `assets/pins/${e ? 'pin-online2.png' : 'pin-offline2.png'}`,
      scaledSize: new google.maps.Size(32, 32),
      anchor: new google.maps.Point(16, 16),
    };
  }

  async ngOnInit(): Promise<void> {
    const gateways = (await firstValueFrom(this.service.getGateways()))
      .datos as GatewayMap[];
    gateways.forEach((g) => {
      const estado = HelperService.checkOnline(g.lastSeenAt);
      g.online = estado;
      g.icon = this.iconGateway(estado);
    });
    this.gateways = { datos: gateways, totalCount: gateways.length };
  }
}
