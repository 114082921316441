import { Component, OnInit } from '@angular/core';
import { ResumenService } from '../resumen.service';
import { firstValueFrom } from 'rxjs';
import { IResumen, IResumenCliente } from 'modelos/src';
import { ITile } from '../tile/tile.component';
import { HelperService } from 'src/app/auxiliares/helper.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public resumen?: IResumen;
  public loading = false;

  public tiles: ITile[] = [];
  public resumenes: IResumenCliente[] = [];

  constructor(private resumenService: ResumenService) {}

  private parseTiles() {
    if (this.resumen?.cantClientes) {
      const clientes: ITile = {
        color: HelperService.stringToColor('Clientes'),
        title: 'Clientes',
        content: `${this.resumen?.cantClientes}`,
      };
      this.tiles.push(clientes);
    }

    if (this.resumen?.cantDispositivo) {
      const dispositivos: ITile = {
        color: HelperService.stringToColor('Dispositivos'),
        title: 'Dispositivos',
        content: `${this.resumen?.cantDispositivo}`,
      };
      this.tiles.push(dispositivos);
    }

    if (this.resumen?.cantGateways) {
      const gateways: ITile = {
        color: HelperService.stringToColor('Gateways'),
        title: 'Gateways',
        content: `${this.resumen?.cantGateways}`,
      };
      this.tiles.push(gateways);
    }

    if (this.resumen?.cantUsuarios) {
      const usuarios: ITile = {
        color: HelperService.stringToColor('Usuarios'),
        title: 'Usuarios',
        content: `${this.resumen?.cantUsuarios}`,
      };
      this.tiles.push(usuarios);
    }

    if (this.resumen?.cantTipoDispositivos) {
      const tipos: ITile = {
        color: HelperService.stringToColor('Tipos de Dispositivos'),
        title: 'Tipos de Dispositivos',
        content: `${this.resumen?.cantTipoDispositivos}`,
      };
      this.tiles.push(tipos);
    }
  }

  private parseResumenes() {
    if (this.resumen?.clientes)
      for (const c of this.resumen?.clientes) {
        const resumen: IResumenCliente = {
          cliente: c.cliente || 'Sin Configurar',
          cantDispositivo: c.cantDispositivo || 0,
          cantUsuarios: c.cantUsuarios || 0,
          cantIntegraciones: c.cantIntegraciones || 0,
          cantTipoDispositivos: c.cantTipoDispositivos || 0,
        };
        this.resumenes.push(resumen);
      }
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true;
      this.resumen = await firstValueFrom(this.resumenService.getResumen());
      this.parseTiles();
      this.parseResumenes();
      console.log(this.resumen);
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
