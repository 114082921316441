import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoDispositivosComponent } from './listado-dispositivos/listado-dispositivos.component';
import { CrearEditarDispositivosComponent } from './crear-editar-dispositivos/crear-editar-dispositivos.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { DispositivosRoutingModule } from './dispositivos.routing.module';
import { TablaUplinksComponent } from './detalles-dispositivo/tabla-uplinks/tabla-uplinks.component';
import { DetallesDispositivoComponent } from './detalles-dispositivo/detalles-dispositivo.component';
import { AppkeyComponent } from './appkey/appkey.component';

@NgModule({
  declarations: [
    ListadoDispositivosComponent,
    CrearEditarDispositivosComponent,
    TablaUplinksComponent,
    DetallesDispositivoComponent,
    AppkeyComponent,
  ],
  imports: [CommonModule, DispositivosRoutingModule, AuxiliaresModule],
})
export class DispositivosModule {}
