<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title></mat-card-header
  >

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form id="loginForm" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <!-- Tipo de Dispositivo -->
      <ng-container>
        <mat-form-field>
          <mat-label>Tipo de Dispositivo</mat-label>
          <mat-select formControlName="nombre" required>
            <mat-option *ngFor="let dato of tipoDispositivos" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- URL Integración  -->
      <mat-form-field>
        <mat-label>URL Integración</mat-label>
        <input
          matInput
          placeholder="URL Integración"
          formControlName="integrationUrl"
        />
      </mat-form-field>

      <!-- Lora Servers -->
      <div formArrayName="loraServers">
        <div
          *ngFor="let loraServer of configLoraServers?.controls; let i = index"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-around center"
            fxLayoutGap="10px"
            [formGroupName]="i"
          >
            <!-- Lora Server -->
            <mat-form-field>
              <mat-label>Lora Server</mat-label>
              <mat-select
                formControlName="idLoraServer"
                (selectionChange)="cambioLoraServer(i)"
              >
                <mat-option *ngFor="let dato of loraServers" [value]="dato._id">
                  {{ dato.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Device Profile -->
            <mat-form-field>
              <mat-label>Device Profile</mat-label>
              <mat-select formControlName="deviceProfileID" multiple>
                <mat-option
                  *ngFor="let dato of deviceProfiles[i]?.deviceProfiles || []"
                  [value]="valueDeviceProfile(i, dato)"
                >
                  {{ labelDeviceProfile(i, dato) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Boton Eliminar -->
            <button
              mat-mini-fab
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarLoraServer(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <!-- Boton agregar Unidad de negocio -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarLoraServer()"
        >
          Agregar Lora Server
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
