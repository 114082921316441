import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ICliente, IFilter, IListado, IQueryParam } from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ClientesService } from '../clientes.service';
import { CrearEditarClientesComponent } from '../crear-editar-clientes/crear-editar-clientes.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-listado-clientes',
  templateUrl: './listado-clientes.component.html',
  styleUrls: ['./listado-clientes.component.scss'],
})
export class ListadoClientesComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<ICliente>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'nombre',
    'activo',
    'logo',
    'tiposDispositivo',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Nombre',
    'Activo',
    'Logo',
    'Tipos de Dispositivo',
    'Acciones',
  ];
  public nombreTabla = 'admin-agro-v2-clientes';
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  public estado: IFiltroTabla = {
    elementos: [
      { nombre: 'Activos', _id: 'true' },
      { nombre: 'Inactivos', _id: 'false' },
    ],
    filter: {
      field: 'activo',
      value: true,
    },
    label: 'Estado',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private filter: IFilter = { activo: true };
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
    sort: 'nombre',
    filter: JSON.stringify(this.filter),
  };

  // Listado Continuo
  public clientes$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: ClientesService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '900px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarClientesComponent, config);
  }

  public async editar(data: ICliente) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarClientesComponent, config);
  }

  public async eliminar(dato: ICliente): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el cliente ${dato.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    await this.actualizar();
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
