<div class="resumen-container">
  <div class="resumen-title">
    <p>{{ resumen?.cliente }}</p>
  </div>
  <div class="resumen-contenido">
    <mat-list role="list">
      <mat-list-item role="listitem">
        <div class="list-item">
          <p>Dispositivos</p>
          <p class="valor">
            {{ resumen?.cantDispositivo }}
          </p>
        </div>
      </mat-list-item>
      <mat-list-item role="listitem">
        <div class="list-item">
          <p>Módulos</p>
          <p class="valor">
            {{ resumen?.cantTipoDispositivos }}
          </p>
        </div>
      </mat-list-item>
      <mat-list-item role="listitem">
        <div class="list-item">
          <p>Usuarios</p>
          <p class="valor">
            {{ resumen?.cantUsuarios }}
          </p>
        </div>
      </mat-list-item>
      <mat-list-item role="listitem">
        <div class="list-item">
          <p>Integraciones</p>
          <p class="valor">
            {{ resumen?.cantIntegraciones }}
          </p>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
