import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IDeviceProfileChirpstack,
  IOrganizationChirpstack,
  IServiceProfileChirpstack,
} from 'modelos/src';
import { HttpService } from '../../auxiliares/http.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ChirpstackService {
  constructor(private http: HttpService) {}

  public getDeviceProfiles(
    serverUrl: string,
    token: string,
    organizationID: string
  ): Observable<IDeviceProfileChirpstack[]> {
    let params = new HttpParams();
    params = params.set('serverUrl', serverUrl);
    params = params.set('token', token);
    params = params.set('organizationID', organizationID);
    //
    return this.http.get(`/chirpstack/device-profiles`, { params });
  }

  public getServiceProfiles(
    serverUrl: string,
    token: string,
    organizationID: string
  ): Observable<IServiceProfileChirpstack[]> {
    let params = new HttpParams();
    params = params.set('serverUrl', serverUrl);
    params = params.set('token', token);
    params = params.set('organizationID', organizationID);
    //
    return this.http.get(`/chirpstack/service-profiles`, { params });
  }

  public getOrganizations(
    serverUrl: string,
    token: string
  ): Observable<IOrganizationChirpstack[]> {
    let params = new HttpParams();
    params = params.set('serverUrl', serverUrl);
    params = params.set('token', token);
    //
    return this.http.get(`/chirpstack/organizations`, { params });
  }
}
