import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetallesDispositivoComponent } from './detalles-dispositivo/detalles-dispositivo.component';
import { ListadoDispositivosComponent } from './listado-dispositivos/listado-dispositivos.component';

const routes: Routes = [
  {
    path: '',
    component: ListadoDispositivosComponent,
  },
  {
    path: ':id',
    component: DetallesDispositivoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DispositivosRoutingModule {}
