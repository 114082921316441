import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HelperService } from '../../../auxiliares/helper.service';
import { DispositivosService } from '../dispositivos.service';
import { firstValueFrom } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-appkey',
  templateUrl: './appkey.component.html',
  styleUrls: ['./appkey.component.scss'],
})
export class AppkeyComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;

  public appkey?: string;

  constructor(
    private fb: UntypedFormBuilder,
    private service: DispositivosService,
    private helper: HelperService,
    private dialogRef: MatDialogRef<AppkeyComponent>
  ) {}

  private createForm(): void {
    this.form = this.fb.group({
      deveui: ['', Validators.required],
      llave: ['', Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.form?.value;
      const response = await firstValueFrom(
        this.service.appkey(data.deveui, data.llave)
      );

      this.appkey = response.appkey;
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }

  ngOnDestroy(): void {}
}
