import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoTipoDispositivosComponent } from './listado-tipo-dispositivos/listado-tipo-dispositivos.component';
import { CrearEditarTipoDispositivosComponent } from './crear-editar-tipo-dispositivos/crear-editar-tipo-dispositivos.component';
import { TipoDispositivosRoutingModule } from './tipo-dispositivos.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@NgModule({
  declarations: [
    ListadoTipoDispositivosComponent,
    CrearEditarTipoDispositivosComponent,
  ],
  imports: [CommonModule, TipoDispositivosRoutingModule, AuxiliaresModule],
})
export class TipoDispositivosModule {}
