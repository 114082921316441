import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  ICrearGateway,
  IFilter,
  IGateway,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { GatewayMap } from '../gateways/gateways.component';
import { Gateways2Service } from '../gateways-2.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CrearEditarGatewaysComponent } from '../../gateways/crear-editar-gateways/crear-editar-gateways.component';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss'],
})
export class ListaComponent implements OnInit, OnDestroy {
  @Input() gateways?: IListado<GatewayMap>;
  @Input() selected?: GatewayMap;
  @Output() selectedChange: EventEmitter<GatewayMap> = new EventEmitter();

  public searchText: string = '';
  public gatewayList: GatewayMap[] = [];
  public filteredGatewayList: GatewayMap[] = [];

  public gatewaysGPE$?: Subscription;
  public gatewaysGPE: IGateway[] = [];

  constructor(
    private service: Gateways2Service,
    private listados: ListadosService,
    private matDialog: MatDialog
  ) {}

  public searchKey(data: string) {
    this.searchText = data;
    this.search();
  }

  public search() {
    this.filteredGatewayList =
      this.searchText === ''
        ? this.gatewayList
        : this.gatewayList.filter((element) => {
            return element
              .name!.toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
  }

  public seleccionar(g: GatewayMap) {
    this.searchText = g.name!;
    this.search();
    this.selectedChange?.emit(g);
  }

  public clear() {
    this.searchText = '';
    this.selectedChange?.emit(undefined);
    this.search();
  }

  public async create(g: GatewayMap) {
    try {
      const gateway: ICrearGateway = {
        gatewayEui: g.id,
        datos: {
          nombre: g.name!,
          descripcion: g.description!,
        },
      };
      if (g.location) {
        gateway.ubicacion = {
          lat: g.location.latitude!,
          lng: g.location.longitude!,
        };
      }
      await firstValueFrom(this.service.crear(gateway));
    } catch (error) {
      console.error(error);
    }
  }

  public edit(g: GatewayMap) {
    const gateway = this.gatewaysGPE.find((element) => {
      return element.gatewayEui === g.id;
    });
    const config: MatDialogConfig = {
      data: gateway,
      width: '700px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarGatewaysComponent, config);
  }

  public checkCreado(g: GatewayMap) {
    return this.gatewaysGPE.some((element) => {
      return element.gatewayEui === g.id;
    });
  }

  private async listar(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
    };
    this.gatewaysGPE$?.unsubscribe();
    this.gatewaysGPE$ = this.listados
      .subscribe<IListado<IGateway>>('gateways', query)
      .subscribe((data) => {
        this.gatewaysGPE = data.datos;
        console.log(`listado de gateways`, data);
      });
    await this.listados.getLastValue('gateways', query);
  }

  async ngOnInit(): Promise<void> {
    this.gatewayList = this.gateways?.datos!;
    this.filteredGatewayList = this.gateways?.datos!;
    await this.listar();
  }

  ngOnDestroy(): void {
    this.gatewaysGPE$?.unsubscribe();
  }
}
