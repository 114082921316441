<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title></mat-card-header
  >

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Username  -->
      <mat-form-field>
        <mat-label>Nombre de usuario</mat-label>
        <input
          matInput
          type="search"
          placeholder="Nombre de usuario"
          formControlName="username"
          required
        />
      </mat-form-field>

      <!-- Password -->
      <mat-form-field>
        <mat-label>Clave</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'search'"
          [autocomplete]="!this.data ? 'new-password' : 'search'"
          placeholder="Clave"
          formControlName="password"
          [required]="this.data ? false : true"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="$event.stopPropagation(); hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>

      <!-- Permisos -->
      <div formArrayName="permisos">
        <div
          *ngFor="
            let permiso of formArrayPermisos?.controls;
            let i = index;
            let even = even
          "
        >
          <div
            [formGroupName]="i"
            style="padding: 0.5em; margin-bottom: 1em; border: 1px solid #ccc"
            [style.background]="even ? '#d3d3d31f' : 'transparent'"
          >
            <mat-form-field>
              <mat-select placeholder="Nivel" formControlName="nivel" required>
                <mat-option *ngFor="let dato of niveles" [value]="dato">
                  {{ dato }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-select placeholder="Rol" formControlName="rol" required>
                <mat-option *ngFor="let dato of roles" [value]="dato">
                  {{ dato }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Cliente -->
            <ng-container *ngIf="permiso.get('nivel')?.value === 'Cliente'">
              <mat-form-field>
                <mat-label>Cliente</mat-label>
                <mat-select
                  formControlName="idCliente"
                  required
                  (valueChange)="changeCliente($event)"
                >
                  <mat-option
                    *ngFor="let dato of clientes"
                    [value]="dato._id"
                    >{{ dato.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </ng-container>

            <mat-form-field>
              <mat-label>Tipos de Dispositivo</mat-label>
              <mat-select formControlName="tiposDispositivo" multiple>
                <mat-option *ngFor="let dato of tipos" [value]="dato">{{
                  dato
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Boton Eliminar Permiso -->
            <button
              mat-mini-fab
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarPermiso(i)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <!-- Boton Agregar Permiso -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarPermiso()"
        >
          Agregar Permiso
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
