import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../modulos/login/login.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private router: Router, private loginService: LoginService) {}

  canActivate() {
    const token = this.loginService.getToken();
    if (token) {
      return true;
    } else {
      return this.router.parseUrl('/login');
    }
  }

  canActivateChild() {
    const token = this.loginService.getToken();
    if (token) {
      return true;
    } else {
      return this.router.parseUrl('/login');
    }
  }
}
