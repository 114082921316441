<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <p>{{ message }}</p>

    <mat-checkbox [(ngModel)]="checkValue">{{ checkText }}</mat-checkbox>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" fxLayoutAlign="end center">
    <button
      mat-raised-button
      color="primary"
      (click)="
        matDialogRef.close({ confirm: true, checkValue: this.checkValue })
      "
    >
      {{ btnOkText }}
    </button>
    <button mat-raised-button (click)="matDialogRef.close({ confirm: false })">
      {{ btnCancelText }}
    </button>
  </mat-card-actions>
</mat-card>
