import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IGatewayChirpstackGetAll,
  IGateway,
  ICrearGateway,
} from 'modelos/src';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class Gateways2Service {
  constructor(private http: HttpService) {}

  public getGateways(): Observable<IListado<IGatewayChirpstackGetAll>> {
    return this.http.get('/gateways/all');
  }

  // IGateways

  public crear(dato: ICrearGateway): Observable<IGateway> {
    return this.http.post(`/gateways`, dato);
  }
}
