import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogModule } from './dialog/dialog.module';
// import { SimpleNotificationsModule } from 'angular2-notifications';
// import { AudioRecorderModule } from './audio-recorder/audio-recorder.module';
// import { ChartModule } from './chart/chart.module';
// import { ColorPickerModule } from './color-picker/color-picker.module';
import { ColumnSelectModule } from './column-select/column-select.module';
// import { DialogModule } from './dialog/dialog.module';
import { FiltroTablaModule } from './filtro-tabla/filtro-tabla.module';
import { MaterialModule } from './material.module';
import { MatPaginatorGotoModule } from './mat-paginator-goto/mat-paginator-goto.module';
import { TextareaModule } from './textarea/module';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideHttpClient } from '@angular/common/http';
// import { PolygonDrawModule } from './polygon-draw/polygon-draw.module';
// import { UbicarModule } from './ubicar/ubicar.module';
// import { NgSelectModule } from '@ng-select/ng-select';
// import { ChangelogModule } from './changelog/changelog.module';

@NgModule({
  imports: [
    // CommonModule,
    // SimpleNotificationsModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    // RouterModule,
    DragDropModule,
    FlexLayoutModule,
    MaterialModule,
    GoogleMapsModule,
    // SimpleNotificationsModule,
    // CountdownModule,
    // NgxAudioPlayerModule,
    // GalleryModule,
    // NgSelectModule,
    // LightboxModule
    //
    // ColorPickerModule,
    ColumnSelectModule,
    DialogModule,
    FiltroTablaModule,
    MatPaginatorGotoModule,
    TextareaModule,
    // PolygonDrawModule,
    // UbicarModule,
    // ChartModule,
    // AudioRecorderModule,
    // ChangelogModule,
  ],
  providers: [provideHttpClient()],
})
export class AuxiliaresModule {}
