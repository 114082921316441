<div class="container">
  <div class="map-container">
    <app-mapa
      [gateways]="gateways.datos"
      [(selected)]="selected"
      *ngIf="gateways"
    ></app-mapa>
  </div>
  <div class="table-container">
    <app-lista
      [gateways]="gateways"
      [(selected)]="selected"
      *ngIf="gateways"
    ></app-lista>
  </div>
</div>
