import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { ClientesModule } from '../clientes/clientes.module';
import { DispositivosModule } from '../dispositivos/dispositivos.module';
import { GatewaysModule } from '../gateways/gateways.module';
import { LandingModule } from '../landing/landing.module';
import { LogUplinksModule } from '../logUplinks/dispositivos.module';
import { LoraServersModule } from '../lora-servers/lora-servers.module';
import { LotesDispositivosModule } from '../lotes-dispositivos/lote-dispositivos.module';
import { TipoDispositivosModule } from '../tipo-dispositivos/tipo-dispositivos.module';
import { UsuariosModule } from '../usuarios/usuarios.module';
import { NavigationComponent } from './navigation/navigation.component';
import { Gateways2Module } from '../gateways-2/gateways-2.module';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => LandingModule,
      },
      {
        path: 'clientes',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => ClientesModule,
      },
      {
        path: 'usuarios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => UsuariosModule,
      },
      {
        path: 'dispositivos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => DispositivosModule,
      },
      {
        path: 'lotes',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => LotesDispositivosModule,
      },
      {
        path: 'tipoDispositivos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => TipoDispositivosModule,
      },
      {
        path: 'loraServers',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => LoraServersModule,
      },
      {
        path: 'logUplinks',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => LogUplinksModule,
      },
      {
        path: 'gateways',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => Gateways2Module,
      },
      {
        path: 'gateways-gpe',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => GatewaysModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
