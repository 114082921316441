<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="accent"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em; overflow: auto">
  <table style="width: 100%" *ngIf="dispositivo">
    <thead>
      <tr>
        <th>Device Name</th>
        <th>DevEUI</th>
        <th>AppKey</th>
        <th>Tipo</th>
        <th>Última Comunicación</th>
      </tr>
    </thead>

    <tbody>
      <tr style="text-align: center">
        <td>{{ dispositivo.deviceName }}</td>
        <td>{{ dispositivo.deveui }}</td>
        <td>{{ dispositivo.appkey }}</td>
        <td>{{ dispositivo.tipo }}</td>
        <td>
          {{ dispositivo.fechaUltimaComunicacion | date: "short" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="mat-elevation-z8" style="margin: 1em">
  <mat-tab-group>
    <!-- Uplinks -->
    <mat-tab label="Uplinks">
      <mat-divider></mat-divider>
      <app-tabla-uplinks [dispositivo]="dispositivo"></app-tabla-uplinks>
    </mat-tab>
  </mat-tab-group>
</div>

<br />
