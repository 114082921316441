<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 70vh; overflow: auto">
    <form
      *ngIf="form"
      id="loginForm"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <!-- Nombre Cliente  -->
      <mat-form-field>
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Logo  -->
      <mat-form-field [formGroupName]="'imagenes'">
        <mat-label>URL Logo</mat-label>
        <input matInput placeholder="https://logo.png" formControlName="logo" />
      </mat-form-field>

      <!-- Tipos de Dispositivos -->
      <mat-form-field>
        <mat-label>Tipo de Dispositivo</mat-label>
        <mat-select formControlName="tiposDispositivo" multiple>
          <mat-option *ngFor="let tipo of tipoDispositivos" [value]="tipo">
            {{ tipo }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- INTEGRACIONES -->
      <div formArrayName="integraciones">
        <div
          *ngFor="
            let integracion of formArrayIntegraciones?.controls;
            let i = index
          "
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-around center"
            fxLayoutGap="10px"
            [formGroupName]="i"
            style="
              border: 1px solid;
              padding: 0.5em;
              border-radius: 5px;
              margin-bottom: 0.5em;
            "
          >
            <ng-container>
              <div>
                <!-- Tipo Integracion -->
                <mat-form-field>
                  <mat-label>Tipo de Integración</mat-label>
                  <mat-select
                    formControlName="tipoIntegracion"
                    (selectionChange)="cambioTipoIntegracion(i)"
                  >
                    <mat-option
                      *ngFor="let tipo of tipoIntegraciones"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Tipo Dispositivo -->
                <mat-form-field>
                  <mat-label>Tipo de Dispositivo</mat-label>
                  <mat-select formControlName="tipoDispositivo">
                    <mat-option
                      *ngFor="let tipo of tipoDispositivos"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Endpoint  -->
                <mat-form-field
                  *ngIf="
                    formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'HTTPS'
                  "
                >
                  <mat-label>Endpoint</mat-label>
                  <input
                    matInput
                    placeholder="https://miapi.com"
                    formControlName="endpoint"
                  />
                </mat-form-field>

                <!-- Metodo HTTP -->
                <mat-form-field
                  *ngIf="
                    formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'HTTPS'
                  "
                >
                  <mat-label>Metodo HTTP</mat-label>
                  <mat-select formControlName="method">
                    <mat-option *ngFor="let tipo of metodosHttp" [value]="tipo">
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Ubicacion Credenciales -->
                <mat-form-field
                  *ngIf="
                    formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'HTTPS'
                  "
                >
                  <mat-label>Ubicacion Credenciales</mat-label>
                  <mat-select formControlName="ubicacionCredenciales">
                    <mat-option
                      *ngFor="let tipo of ubicacionesCredenciales"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Credenciales 1 y 2 -->
              <div
                fxFlex="50"
                style="
                  background: #68656587;
                  padding: 0.5em;
                  border-radius: 5px;
                "
              >
                <!-- Credenciales -->
                <div
                  fxLayout="row wrap"
                  *ngIf="
                    (formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'INFLUXV1' ||
                      formArrayIntegraciones.at(i).get('tipoIntegracion')
                        ?.value === 'INFLUXV2') &&
                    formCredenciales(i)
                  "
                  formGroupName="credenciales"
                >
                  <ng-container
                    *ngFor="let item of formCredenciales(i).controls | keyvalue"
                  >
                    <mat-form-field>
                      <mat-label> {{ item.key | titlecase }}</mat-label>
                      <input matInput [formControlName]="item.key" />
                    </mat-form-field>
                  </ng-container>
                </div>

                <!-- Credenciales 2 -->
                <div
                  fxLayout="row wrap"
                  *ngIf="
                    formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'HTTPS' ||
                    formArrayIntegraciones.at(i).get('tipoIntegracion')
                      ?.value === 'MariaDB'
                  "
                  formArrayName="credenciales2"
                >
                  <div
                    *ngFor="
                      let integracion of formArrayCredenciales2(i)?.controls;
                      let j = index
                    "
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="space-around center"
                      fxLayoutGap="10px"
                      [formGroupName]="j"
                    >
                      <!-- KEY  -->
                      <mat-form-field>
                        <mat-label>KEY</mat-label>
                        <input
                          matInput
                          placeholder="username"
                          formControlName="key"
                        />
                      </mat-form-field>

                      <!-- VALUE  -->
                      <mat-form-field>
                        <mat-label>VALUE</mat-label>
                        <input
                          matInput
                          placeholder="admin"
                          formControlName="value"
                        />
                      </mat-form-field>

                      <!-- Boton Eliminar -->
                      <button
                        mat-mini-fab
                        color="warn"
                        matTooltip="Eliminar"
                        (click)="eliminarCredenciales2(i, j)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>

                  <!-- Agregar Integracion -->
                  <button
                    mat-raised-button
                    color="accent"
                    type="button"
                    (click)="agregarCredenciales2(i)"
                  >
                    Agregar Credencial
                  </button>
                </div>
              </div>
            </ng-container>
            <!-- Boton Eliminar -->
            <div fxLayoutAlign="center end">
              <button
                mat-mini-fab
                color="warn"
                matTooltip="Eliminar"
                (click)="eliminarIntegracion(i)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Agregar Integracion -->
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="agregarIntegracion()"
      >
        Agregar Integración
      </button>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <p>{{ form?.errors }}</p>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
