import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoLoraServersComponent } from './listado-lora-servers/listado-lora-servers.component';
import { CrearEditarLoraServersComponent } from './crear-editar-lora-servers/crear-editar-lora-servers.component';
import { LoraServersRoutingModule } from './lora-servers.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@NgModule({
  declarations: [ListadoLoraServersComponent, CrearEditarLoraServersComponent],
  imports: [CommonModule, LoraServersRoutingModule, AuxiliaresModule],
})
export class LoraServersModule {}
