import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-check',
  templateUrl: './confirmation-check.component.html',
  styleUrls: ['./confirmation-check.component.scss'],
})
export class ConfirmationCheckComponent implements OnInit {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;

  checkText: string;
  checkValue: boolean;

  constructor(
    public matDialogRef: MatDialogRef<ConfirmationCheckComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
    this.checkText = this.data.checkText;
    this.checkValue = this.data.checkValue || false;
  }

  ngOnInit() {}
}
