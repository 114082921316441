import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ClientesRoutingModule } from './clientes.routing.module';
import { ListadoClientesComponent } from './listado-clientes/listado-clientes.component';
import { CrearEditarClientesComponent } from './crear-editar-clientes/crear-editar-clientes.component';

@NgModule({
  declarations: [ListadoClientesComponent, CrearEditarClientesComponent],
  imports: [CommonModule, AuxiliaresModule, ClientesRoutingModule],
})
export class ClientesModule {}
