import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  ICliente,
  IDispositivo,
  IFilter,
  IListado,
  IQueryParam,
  ITipoDispositivo,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { AppkeyComponent } from '../appkey/appkey.component';
import { CrearEditarDispositivosComponent } from '../crear-editar-dispositivos/crear-editar-dispositivos.component';
import { DispositivosService } from '../dispositivos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-listado-dispositivos',
  templateUrl: './listado-dispositivos.component.html',
  styleUrls: ['./listado-dispositivos.component.scss'],
})
export class ListadoDispositivosComponent implements OnInit {
  public loading = true;

  public usuario?: IUsuario;

  public usarColores = localStorage.getItem('usarColores') ? true : false;

  // TABLA
  public nombreTabla = 'admin-agro-v2-dispositivos';
  public dataSource = new MatTableDataSource<IDispositivo>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'deveui',
    'deviceName',
    'appkey',
    'tipoDispositivo',
    'conectividad',
    'lote',
    'cliente',
    'fechaUltimaComunicacion',
    'loraServer',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'DevEUI',
    'Device Name',
    'Appkey',
    'Tipo',
    'Conectividad',
    'Lote',
    'Cliente',
    'Última Comunicación',
    'Lora Server',
    'Acciones',
  ];
  // Filtros Tabla
  public search: IRegExpSearch = {
    fields: ['deveui', 'deviceName'],
  };
  public conectividad: IFiltroTabla = {
    elementos: [
      { nombre: '4G', _id: '4G' },
      { nombre: 'Lora', _id: 'Lora' },
    ],
    filter: {
      field: 'conectividad',
    },
    label: 'Conectividad',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public tipoDispositivo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idTipoDispositivo',
    },
    label: 'Tipo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private populate = [
    {
      path: 'cliente',
      select: 'nombre admin',
    },
    {
      path: 'loteDispositivo',
      select: 'nombre',
    },
    {
      path: 'tipoDispositivo',
      select: 'nombre',
    },
    {
      path: 'loraServer',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
    sort: 'deviceName',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public dispositivos$?: Subscription;
  public clientes$?: Subscription;
  public tipoDispositivos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: DispositivosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) { }

  public cambioUsarColores(event: MatCheckboxChange): void {
    if (event.checked) {
      localStorage.setItem('usarColores', 'true');
    } else {
      localStorage.removeItem('usarColores');
    }
  }

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async appkey(): Promise<void> {
    const config: MatDialogConfig = {
      width: '800px',

      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    this.matDialog.open(AppkeyComponent, config);
  }

  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '800px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarDispositivosComponent, config);
  }

  public async editar(data: IDispositivo) {
    const config: MatDialogConfig = {
      data,
      width: '800px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarDispositivosComponent, config);
  }

  public async eliminar(dato: IDispositivo): Promise<void> {
    let confirm: boolean;
    let eliminarEnServer = false;

    if (dato.conectividad === 'LORA') {
      const response = await this.dialogService.confirmCheck(
        'Confirme la acción',
        `¿Eliminar el dispositivos ${dato.deviceName}?`,
        'Eliminar de Lora Server',
        false
      );
      confirm = response.confirm;
      eliminarEnServer = response.checkValue;
    } else {
      confirm = await this.dialogService.confirm(
        'Confirme la acción',
        `¿Eliminar el dispositivo ${dato.deviceName}?`
      );
    }

    if (confirm) {
      this.loading = true;
      try {
        await this.service
          .eliminar(dato._id, { eliminarEnServer })
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
        this.loading = false;
      }
      this.loading = false;
    }
  }

  public async getFromLoraServer(dato: IDispositivo): Promise<void> {
    this.loading = true;
    try {
      const reponse = await this.service
        .getFromLoraServer(dato._id)
        .pipe(first())
        .toPromise();
      this.helper.notifSuccess('Dispositivo encontrado, ver en la consola');
      console.info(`Dispostivo ${dato.deviceName}`, reponse);
    } catch (error) {
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public async deleteFromLoraServer(dato: IDispositivo): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el dispositivo ${dato.deviceName} del lora server ${dato.loraServer?.nombre}?`
    );

    if (confirm) {
      this.loading = true;
      try {
        await this.service
          .deleteFromLoraServer(dato._id)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public async createOnLoraServer(dato: IDispositivo): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Crear el dispositivo ${dato.deviceName} en el lora server ${dato.loraServer?.nombre}?`
    );

    if (confirm) {
      this.loading = true;
      try {
        await this.service
          .createOnLoraServer(dato._id)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Creación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.dispositivos$?.unsubscribe();
    this.dispositivos$ = this.listadosService
      .subscribe<IListado<IDispositivo>>('dispositivos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de dispositivos`, data);
      });
    await this.listadosService.getLastValue('dispositivos', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarTipoDispositivo(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.tipoDispositivos$?.unsubscribe();
    this.tipoDispositivos$ = this.listadosService
      .subscribe<IListado<ITipoDispositivo>>('tipoDispositivos', query)
      .subscribe((data) => {
        this.tipoDispositivo.elementos = data.datos;
        console.log(`listado de tipodispositivos`, data);
      });
    await this.listadosService.getLastValue('tipoDispositivos', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarClientes(),
      this.listarTipoDispositivo(),
      this.usuario = LoginService.getUsuario(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.dispositivos$?.unsubscribe();
    this.clientes$?.unsubscribe();
    this.tipoDispositivos$?.unsubscribe();
  }
}
