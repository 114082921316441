import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ICreateLoteDispositivo, ILoteDispositivo } from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { LoteDispositivosService } from '../lote-dispositivos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-lotes-dispositivos',
  templateUrl: './crear-editar-lotes-dispositivos.component.html',
  styleUrls: ['./crear-editar-lotes-dispositivos.component.scss'],
})
export class CrearEditarLotesDispositivosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILoteDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarLotesDispositivosComponent>,
    private helper: HelperService,
    private service: LoteDispositivosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Lote de Dispositivos'
      : 'Crear Lote de Dispositivos';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      key: [this.data?.key, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateLoteDispositivo = {
      nombre: this.form?.get('nombre')?.value,
      key: this.form?.get('key')?.value,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }
}
