import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ICreateLoraServer,
  ILoraServer,
  IOrganizationChirpstack,
  IServiceProfileChirpstack,
  IServiceProfileOrbiwise,
  TipoLoraServer,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ChirpstackService } from '../../chirpstack/chirpstack.service';
import { OrbiwiseService } from '../../orbiwise/chirpstack.service';
import { LoraServersService } from '../lora-servers.service';
import { firstValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-lora-servers',
  templateUrl: './crear-editar-lora-servers.component.html',
  styleUrls: ['./crear-editar-lora-servers.component.scss'],
})
export class CrearEditarLoraServersComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  public tiposLoraServer: TipoLoraServer[] = ['ChirpStack', 'Orbiwise'];
  // Chirpstack
  public organizationsChirpstack: IOrganizationChirpstack[] = [];
  public serviceProfilesChirpstack: IServiceProfileChirpstack[] = [];
  // Orbiwise
  public serviceProfilesOrbiwise: IServiceProfileOrbiwise[] = [];

  get formChirpstack() {
    return this.form?.get('chirpstack') as UntypedFormGroup;
  }

  get formOrbiwise() {
    return this.form?.get('orbiwise') as UntypedFormGroup;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILoraServer,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarLoraServersComponent>,
    private service: LoraServersService,
    private helper: HelperService,
    private chirpstackService: ChirpstackService,
    private orbiwiseService: OrbiwiseService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Lora Server' : 'Crear Lora Server';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      tipo: [this.data?.tipo, Validators.required],
      url: [this.data?.url, Validators.required],
      integrationUrl: [this.data?.integrationUrl],
      // Chirpstack
      chirpstack: this.fb.group({
        token: [this.data?.chirpstack?.token],
        organizationID: [this.data?.chirpstack?.organizationID],
        serviceProfileID: [this.data?.chirpstack?.serviceProfileID],
      }),
      // Orbiwise
      orbiwise: this.fb.group({
        user: [this.data?.orbiwise?.user],
        pass: [this.data?.orbiwise?.pass],
        serviceProfileUUID: [this.data?.orbiwise?.serviceProfileUUID],
      }),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateLoraServer = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        console.log(data);
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public cambioUrl(): void {
    const tipoLoraServer = this.form?.get('tipo')?.value;
    if (tipoLoraServer === 'ChirpStack') {
      this.formChirpstack?.patchValue({ organizationID: null });
      this.formChirpstack?.patchValue({ serviceProfileID: null });
      this.getChirpstackOrganizations();
    }
    if (tipoLoraServer === 'Orbiwise') {
      this.formOrbiwise?.patchValue({ serviceProfileUUID: null });
      this.getOrbiwiseServiceProfiles();
    }
  }

  public cambioToken(): void {
    const tipoLoraServer = this.form?.get('tipo')?.value;
    if (tipoLoraServer === 'ChirpStack') {
      this.formChirpstack?.patchValue({ organizationID: null });
      this.formChirpstack?.patchValue({ serviceProfileID: null });
      this.getChirpstackOrganizations();
    }
  }

  public cambioUser(): void {
    const tipoLoraServer = this.form?.get('tipo')?.value;
    if (tipoLoraServer === 'Orbiwise') {
      this.getOrbiwiseServiceProfiles();
    }
  }

  public cambioPass(): void {
    const tipoLoraServer = this.form?.get('tipo')?.value;
    if (tipoLoraServer === 'Orbiwise') {
      this.getOrbiwiseServiceProfiles();
    }
  }

  public cambioOrganization(): void {
    const tipoLoraServer = this.form?.get('tipo')?.value;
    if (tipoLoraServer === 'ChirpStack') {
      this.formChirpstack?.patchValue({ serviceProfileID: null });
      this.getChirpstackServiceProfiles();
    }
  }

  // Chirpstack

  public async getChirpstackOrganizations(): Promise<void> {
    const url = this.form?.get('url')?.value;
    const token = this.formChirpstack?.get('token')?.value;
    if (url && token) {
      this.organizationsChirpstack = await firstValueFrom(
        this.chirpstackService.getOrganizations(url, token)
      );
    }
  }

  public async getChirpstackServiceProfiles(): Promise<void> {
    const url = this.form?.get('url')?.value;
    const token = this.formChirpstack?.get('token')?.value;
    const organizationID = this.formChirpstack?.get('organizationID')?.value;
    if (url && token && organizationID) {
      this.serviceProfilesChirpstack = await firstValueFrom(
        this.chirpstackService.getServiceProfiles(url, token, organizationID)
      );
    }
  }

  // Orbiwise

  public async getOrbiwiseServiceProfiles(): Promise<void> {
    const url = this.form?.get('url')?.value;
    const user = this.formOrbiwise?.get('user')?.value;
    const pass = this.formOrbiwise?.get('pass')?.value;
    if (url && user && pass) {
      this.serviceProfilesOrbiwise = await firstValueFrom(
        this.orbiwiseService.getServiceProfiles(url, user, pass)
      );
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    // Cargar info de chirpstack
    if (this.data?.url && this.data?.chirpstack?.token) {
      await Promise.all([
        this.getChirpstackOrganizations(),
        this.getChirpstackServiceProfiles(),
      ]);
    }
    // Cargar info de orbiwise
    if (
      this.data?.url &&
      this.data?.orbiwise?.user &&
      this.data?.orbiwise?.pass
    ) {
      await Promise.all([this.getOrbiwiseServiceProfiles()]);
    }
    this.loading = false;
  }
}
