import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ICliente,
  IConfigLora,
  ICreateDispositivo,
  IDeviceProfileChirpstack,
  IDeviceProfileOrbiwise,
  IDispositivo,
  IListado,
  ILoraServer,
  ILoteDispositivo,
  IQueryParam,
  ITipoDispositivo,
  IUpdateDispositivo,
  TipoConectividad,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { TextareaComponent } from 'src/app/auxiliares/textarea/textarea/textarea.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ChirpstackService } from '../../chirpstack/chirpstack.service';
import { OrbiwiseService } from '../../orbiwise/chirpstack.service';
import { DispositivosService } from '../dispositivos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-dispositivos',
  templateUrl: './crear-editar-dispositivos.component.html',
  styleUrls: ['./crear-editar-dispositivos.component.scss'],
})
export class CrearEditarDispositivosComponent implements OnInit {
  @ViewChild('textarea') textarea?: TextareaComponent;
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;

  public clientes: ICliente[] = [];
  public loteDispositivos: ILoteDispositivo[] = [];
  public tipoDispositivos: ITipoDispositivo[] = [];
  public loraServers: ILoraServer[] = [];
  public conectividad: TipoConectividad[] = ['4G', 'LORA'];
  public deviceProfiles: any[] = [];
  public crearEnServer = true;

  public tiposDispositivoPorCliente: TipoDispositivo[] = [];

  private deviceProfilesChirpstack: IDeviceProfileChirpstack[] = [];
  private deviceProfilesOrbiwise: IDeviceProfileOrbiwise[] = [];

  // Listado Continuo
  public clientes$?: Subscription;
  public loteDispositivos$?: Subscription;
  public tipoDispositivos$?: Subscription;
  public loraServers$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarDispositivosComponent>,
    private service: DispositivosService,
    private helper: HelperService,
    private listadosService: ListadosService,
    private chirpstackService: ChirpstackService,
    private orbiwiseService: OrbiwiseService
  ) {}

  public getDeveuiErrorMessage() {
    if (this.form?.get('deveuis')?.hasError('error')) {
      return this.form?.get('deveuis')?.getError('error');
    }
    return;
  }

  // Parseo de textarea
  private esHexadecimal(numeroHexadecimal: string) {
    return /^[0-9A-F]+$/gi.test(numeroHexadecimal);
  }
  private separarEnLineas(input: string) {
    input = input.trim();
    const returnArray = [];
    if (input) {
      let inputArray: string[] = input.split('\n');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      for (const linea of inputArray) {
        if (linea) {
          returnArray.push(linea);
        }
      }
      if (returnArray.length) {
        return returnArray;
      }
    }
    return;
  }
  private parsearLinea(input: string) {
    input = input.trim();
    if (input) {
      let inputArray: string[] = input.split(';');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      return inputArray;
    }
    return;
  }
  private errorDeveui(deveui?: string) {
    if (deveui) {
      if (this.esHexadecimal(deveui)) {
        if (deveui.length === 16) {
          return false;
        } else {
          return 'El Deveui debe contener 16 caracteres';
        }
      } else {
        return 'El Deveui contiene caracteres invalidos';
      }
    } else {
      return 'Debe ingresar un deveui';
    }
  }
  private errorAppkey(appkey?: string) {
    if (appkey) {
      if (this.esHexadecimal(appkey)) {
        if (appkey.length === 32) {
          return false;
        } else {
          return 'El Appkey debe contener 32 caracteres';
        }
      } else {
        return 'El Appkey contiene caracteres invalidos';
      }
    } else {
      return false;
    }
  }

  private validarTextarea(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let error: { error: string } | null = null;
      const input: string = control.value;
      const lineas = this.separarEnLineas(input);
      const deveuis: string[] = [];
      const deviceNames: string[] = [];
      // const appkeys: string[] = [];
      if (lineas) {
        for (let i = 0; i < lineas.length; i++) {
          const linea = lineas[i];
          const lineaParseada = this.parsearLinea(linea);
          const deveui = lineaParseada?.[0];
          const deviceName = lineaParseada?.[1];
          const appkey = lineaParseada?.[2];

          const msgErrorDeveui = this.errorDeveui(deveui);
          const msgErrorAppkey = this.errorAppkey(appkey);
          if (msgErrorDeveui) {
            error = { error: `Linea ${i + 1}. ${msgErrorDeveui}` };
          } else if (msgErrorAppkey) {
            error = { error: `Linea ${i + 1}. ${msgErrorAppkey}` };
          } else if (lineaParseada && lineaParseada.length > 3) {
            error = { error: `Linea ${i + 1}. Sobran parámetros ` };
          } else if (!deviceName) {
            error = { error: `Linea ${i + 1}. El device name el obligatorio` };
          } else if (deveui && deveuis.includes(deveui)) {
            error = { error: `Linea ${i + 1}. Deveui repetido ${deveui}` };
          } else if (deviceNames.includes(deviceName)) {
            error = {
              error: `Linea ${i + 1}. Device name repetido ${deviceName}`,
            };
          } else {
            deveuis.push(deveui!);
            deviceNames.push(deviceName);
            // appkeys.push(appkey);
          }
        }
      }
      return error;
    };
  }

  private validarForm(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const input: any = control.value;
      const conectividad = input.conectividad;
      const idLoraServer = input.idLoraServer;
      const deviceProfileID = input.deviceProfileID;

      if (conectividad === 'LORA') {
        if (!idLoraServer) {
          return { error: 'Debe seleccionar un server' };
        }
        if (!deviceProfileID) {
          return { error: 'Debe seleccionar un device profile' };
        }
      }
      return null;
    };
  }

  //

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Dispositivo' : 'Crear Dispositivo';

    this.form = this.fb.group(
      {
        // SOLO EN CREAR
        deveuis: [
          '',
          !this.data ? [Validators.required, this.validarTextarea()] : [],
        ],
        // SOLO EN EDITAR
        deveui: [this.data?.deveui, this.data ? Validators.required : null],
        deviceName: [
          this.data?.deviceName,
          this.data ? Validators.required : null,
        ],
        // SIEMPRE
        idCliente: [this.data?.idCliente, Validators.required],
        appkey: [this.data?.appkey],
        idLoteDispositivo: [this.data?.idLoteDispositivo],
        idTipoDispositivo: [this.data?.idTipoDispositivo, Validators.required],
        conectividad: [this.data?.conectividad || 'LORA', Validators.required],
        idLoraServer: [this.data?.idLoraServer],
        deviceProfileID: [this.data?.deviceProfileID],
      },
      { validators: this.validarForm() }
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData(linea: string) {
    const lineaParseada = this.parsearLinea(linea);
    const idTipoDispositivo = this.form?.get('idTipoDispositivo')?.value;
    const tipoDispositivo = this.tipoDispositivos.find(
      (tipo) => tipo._id === idTipoDispositivo
    );
    const data: ICreateDispositivo = {
      idCliente: this.form?.get('idCliente')?.value,
      deveui: lineaParseada?.[0] || '',
      deviceName: lineaParseada?.[1] || '',
      appkey: lineaParseada?.[2] || this.form?.get('appkey')?.value,
      idLoteDispositivo: this.form?.get('idLoteDispositivo')?.value,
      idTipoDispositivo,
      conectividad: this.form?.get('conectividad')?.value,
      idLoraServer: this.form?.get('idLoraServer')?.value,
      deviceProfileID: this.form?.get('deviceProfileID')?.value,
      tipo: tipoDispositivo?.nombre,
    };
    return data;
  }

  private getUpdateData() {
    const idTipoDispositivo = this.form?.get('idTipoDispositivo')?.value;
    const tipoDispositivo = this.tipoDispositivos.find(
      (tipo) => tipo._id === idTipoDispositivo
    );
    const data: IUpdateDispositivo = {
      idCliente: this.form?.get('idCliente')?.value,
      deveui: this.form?.get('deveui')?.value,
      deviceName: this.form?.get('deviceName')?.value,
      appkey: this.form?.get('appkey')?.value,
      idLoteDispositivo: this.form?.get('idLoteDispositivo')?.value,
      idTipoDispositivo,
      conectividad: this.form?.get('conectividad')?.value,
      idLoraServer: this.form?.get('idLoraServer')?.value,
      deviceProfileID: this.form?.get('deviceProfileID')?.value,
      tipo: tipoDispositivo?.nombre,
    };
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar2(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
        this.dialogRef.close();
      } else {
        const textArea = this.form?.get('deveuis')?.value;
        const lineas = this.separarEnLineas(textArea);
        let creados = 0;
        if (lineas?.length) {
          this.textarea?.scrollTop();
          for (const linea of lineas) {
            try {
              const data = this.getCreateData(linea);
              await firstValueFrom(
                this.service.crear(data, { crearEnServer: this.crearEnServer })
              );
              this.textarea?.eliminarLineaDelTextArea(linea);
              creados++;
            } catch (err) {
              console.error(`Error al crear el dispositivo: ${linea}`);
              console.error(err);
            }
          }
          if (creados) {
            if (creados === lineas.length) {
              this.helper.notifSuccess(
                `Se crearon ${creados} de ${lineas?.length} dispositivos`
              );
            } else {
              this.helper.notifWarn(
                `Se crearon ${creados} de ${lineas?.length} dispositivos`
              );
            }
          } else {
            this.helper.notifError('No se crearon dispositivos');
          }
        }
      }
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public async cambioCliente() {
    const idCliente = this.form?.get('idCliente')?.value;
    if (idCliente) {
      const cliente = this.clientes.find((c) => c._id === idCliente);
      this.tiposDispositivoPorCliente = cliente?.tiposDispositivo || [];

      const idTipoDispositivo = this.form?.get('idTipoDispositivo')?.value;
      const tipoDispositivo = this.tipoDispositivos.find(
        (t) => t._id === idTipoDispositivo
      );
      const nombreTipoDispositivo = tipoDispositivo?.nombre;

      if (
        !nombreTipoDispositivo ||
        !this.tiposDispositivoPorCliente?.includes(nombreTipoDispositivo)
      ) {
        this.form?.patchValue({ idTipoDispositivo: null });
        this.cambioTipoDispositivo();
      }

      if (this.tiposDispositivoPorCliente?.length === 1) {
        const tipoDispositivo = this.tipoDispositivos.find(
          (e) => e.nombre === this.tiposDispositivoPorCliente[0]
        );
        this.form?.patchValue({ idTipoDispositivo: tipoDispositivo?._id });
        this.cambioTipoDispositivo();
      }
    } else {
      this.tiposDispositivoPorCliente = [];
      this.cambioTipoDispositivo();
    }
  }

  public async cambioTipoDispositivo() {
    this.loading = true;
    const idLoraServer = this.form?.get('idLoraServer')?.value;
    const idTipoDispositivo = this.form?.get('idTipoDispositivo')?.value;
    const loraServer = this.loraServers.find((e) => e._id === idLoraServer);
    const tipoDispositivo = this.tipoDispositivos.find(
      (e) => e._id === idTipoDispositivo
    );

    const config: IConfigLora | undefined = tipoDispositivo?.loraServers?.find(
      (e) => e.idLoraServer === idLoraServer
    );

    if (loraServer?.tipo === 'ChirpStack') {
      this.form?.patchValue({ deviceProfileID: null });
      this.deviceProfiles = this.deviceProfilesChirpstack.filter((e) =>
        config?.deviceProfileID?.includes(e.id)
      );
      if (this.deviceProfiles.length === 1) {
        this.form?.patchValue({
          deviceProfileID: (this.deviceProfiles[0] as IDeviceProfileChirpstack)
            .id,
        });
      }
    }
    if (loraServer?.tipo === 'Orbiwise') {
      this.form?.patchValue({ deviceProfileID: null });
      this.deviceProfiles = this.deviceProfilesOrbiwise.filter((e) =>
        config?.deviceProfileID?.includes(e.profile_uuid)
      );
      if (this.deviceProfiles.length === 1) {
        this.form?.patchValue({
          deviceProfileID: (this.deviceProfiles[0] as IDeviceProfileOrbiwise)
            .profile_uuid,
        });
      }
    }
    this.loading = false;
  }

  public async cambioLoraServer() {
    this.loading = true;
    const idLoraServer = this.form?.get('idLoraServer')?.value;
    const idTipoDispositivo = this.form?.get('idTipoDispositivo')?.value;
    const loraServer = this.loraServers.find((e) => e._id === idLoraServer);
    const tipoDispositivo = this.tipoDispositivos.find(
      (e) => e._id === idTipoDispositivo
    );

    const config: IConfigLora | undefined = tipoDispositivo?.loraServers?.find(
      (e) => e.idLoraServer === idLoraServer
    );

    if (loraServer?.tipo === 'ChirpStack') {
      this.form?.patchValue({ deviceProfileID: null });
      this.deviceProfilesChirpstack = await this.getDeviceProfilesChirpstack(
        loraServer
      );
      this.deviceProfiles = this.deviceProfilesChirpstack.filter((e) =>
        config?.deviceProfileID?.includes(e.id)
      );
    }
    if (loraServer?.tipo === 'Orbiwise') {
      this.form?.patchValue({ deviceProfileID: null });
      this.deviceProfilesOrbiwise = await this.getDeviceProfilesOrbiwise(
        loraServer
      );
      this.deviceProfiles = this.deviceProfilesOrbiwise.filter((e) =>
        config?.deviceProfileID?.includes(e.profile_uuid)
      );
    }
    this.loading = false;
  }

  //

  public labelDeviceProfile(dato: any): string | void {
    const idLoraServer = this.form?.get('idLoraServer')?.value;
    const loraServer = this.loraServers.find((ls) => ls._id === idLoraServer);
    if (loraServer?.tipo === 'ChirpStack') {
      const deviceProfile: IDeviceProfileChirpstack = dato;
      return deviceProfile.name;
    }
    if (loraServer?.tipo === 'Orbiwise') {
      const deviceProfile: IDeviceProfileOrbiwise = dato;
      return deviceProfile.profile_name;
    }
  }

  public valueDeviceProfile(dato: any): string | void {
    const idLoraServer = this.form?.get('idLoraServer')?.value;
    const loraServer = this.loraServers.find((ls) => ls._id === idLoraServer);
    if (loraServer?.tipo === 'ChirpStack') {
      const deviceProfile: IDeviceProfileChirpstack = dato;
      return deviceProfile.id;
    }
    if (loraServer?.tipo === 'Orbiwise') {
      const deviceProfile: IDeviceProfileOrbiwise = dato;
      return deviceProfile.profile_uuid;
    }
  }

  // Listados

  private async getDeviceProfilesChirpstack(
    loraServer: ILoraServer
  ): Promise<IDeviceProfileChirpstack[]> {
    if (
      loraServer?.url &&
      loraServer?.chirpstack?.token &&
      loraServer?.chirpstack?.organizationID
    ) {
      const datos = await firstValueFrom(
        this.chirpstackService.getDeviceProfiles(
          loraServer.url,
          loraServer.chirpstack?.token,
          loraServer.chirpstack?.organizationID
        )
      );
      return datos;
    }
    return [];
  }

  private async getDeviceProfilesOrbiwise(
    loraServer: ILoraServer
  ): Promise<IDeviceProfileOrbiwise[]> {
    if (
      loraServer?.url &&
      loraServer?.orbiwise?.user &&
      loraServer?.orbiwise?.pass
    ) {
      const datos = await firstValueFrom(
        this.orbiwiseService.getDeviceProfiles(
          loraServer.url,
          loraServer.orbiwise?.user,
          loraServer.orbiwise?.pass
        )
      );
      console.log('getDeviceProfilesOrbiwise', datos);
      return datos;
    }
    return [];
  }

  private async listarDeviceProfiles(): Promise<void> {
    if (this.data) {
      const idLoraServer = this.data.idLoraServer;
      const idTipoDispositivo = this.data.idTipoDispositivo;

      const loraServer = this.loraServers.find((e) => e._id === idLoraServer);
      const tipoDispositivo = this.tipoDispositivos.find(
        (e) => e._id === idTipoDispositivo
      );

      const config: IConfigLora | undefined =
        tipoDispositivo?.loraServers?.find(
          (e) => e.idLoraServer === loraServer?._id
        );

      if (loraServer?.tipo === 'ChirpStack') {
        this.deviceProfilesChirpstack = await this.getDeviceProfilesChirpstack(
          loraServer
        );
        this.deviceProfiles = this.deviceProfilesChirpstack.filter((e) =>
          config?.deviceProfileID?.includes(e.id)
        );
      }
      if (loraServer?.tipo === 'Orbiwise') {
        this.deviceProfilesOrbiwise = await this.getDeviceProfilesOrbiwise(
          loraServer
        );
        this.deviceProfiles = this.deviceProfilesOrbiwise.filter((e) =>
          config?.deviceProfileID?.includes(e.profile_uuid)
        );
      }
    }
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre tiposDispositivo',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarLoteDispositivos(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.loteDispositivos$?.unsubscribe();
    this.loteDispositivos$ = this.listadosService
      .subscribe<IListado<ILoteDispositivo>>('loteDispositivos', query)
      .subscribe((data) => {
        this.loteDispositivos = data.datos;
        console.log(`listado de loteDispositivos`, data);
      });
    await this.listadosService.getLastValue('loteDispositivos', query);
  }

  private async listarTipoDispositivos(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
    };
    this.tipoDispositivos$?.unsubscribe();
    this.tipoDispositivos$ = this.listadosService
      .subscribe<IListado<ITipoDispositivo>>('tipoDispositivos', query)
      .subscribe((data) => {
        this.tipoDispositivos = data.datos;
        console.log(`listado de tipoDispositivos`, data);
      });
    await this.listadosService.getLastValue('tipoDispositivos', query);
  }

  private async listarLoraServers(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
    };
    this.loraServers$?.unsubscribe();
    this.loraServers$ = this.listadosService
      .subscribe<IListado<ILoraServer>>('loraServers', query)
      .subscribe((data) => {
        this.loraServers = data.datos;
        if (!this.data) {
          if (this.loraServers.length === 1) {
            this.form?.patchValue({
              idLoraServer: this.loraServers[0]._id,
            });
            this.cambioLoraServer();
          }
        }
        console.log(`listado de loraServers`, data);
      });
    await this.listadosService.getLastValue('loraServers', query);
  }

  private setTiposDispositivoPorCliente(idCliente: string) {
    const cliente = this.clientes.find((e) => e._id === idCliente);
    if (cliente) {
      this.tiposDispositivoPorCliente = cliente.tiposDispositivo;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await Promise.all([
      this.listarClientes(),
      this.listarLoteDispositivos(),
      this.listarTipoDispositivos(),
      this.listarLoraServers(),
    ]);
    await this.listarDeviceProfiles();
    if (this.data?._id) {
      this.setTiposDispositivoPorCliente(this.data.idCliente);
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.loteDispositivos$?.unsubscribe();
    this.tipoDispositivos$?.unsubscribe();
    this.loraServers$?.unsubscribe();
  }
}
