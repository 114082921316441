import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoComponent } from './listado/listado.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LogUplinksRoutingModule } from './dispositivos.routing.module';

@NgModule({
  declarations: [ListadoComponent],
  imports: [CommonModule, AuxiliaresModule, LogUplinksRoutingModule],
})
export class LogUplinksModule {}
