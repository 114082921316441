import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ListadoUsuariosComponent } from './listado-usuarios/listado-usuarios.component';
import { CrearEditarUsuariosComponent } from './crear-editar-usuarios/crear-editar-usuarios.component';
import { UsuariosRoutingModule } from './usuarios.routing.module';

@NgModule({
  declarations: [ListadoUsuariosComponent, CrearEditarUsuariosComponent],
  imports: [CommonModule, AuxiliaresModule, UsuariosRoutingModule],
})
export class UsuariosModule {}
