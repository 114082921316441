import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import { HelperService } from 'src/app/auxiliares/helper.service';
import { GatewayMap } from '../gateways/gateways.component';
import { ICoordenadas } from 'modelos/src';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit, OnChanges {
  @Input() gateways?: GatewayMap[];
  @Input() selected?: GatewayMap;
  @Output() selectedChange: EventEmitter<GatewayMap> = new EventEmitter();

  public options: google.maps.MapOptions = HelperService.mapOptions;
  public center: google.maps.LatLngLiteral = HelperService.defaultCenter;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    clickable: true,
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.selected) {
      const coor: ICoordenadas = {
        lat: this.selected?.location?.latitude!,
        lng: this.selected?.location?.longitude!,
      };
      this.center = coor;
    } else {
      this.center = HelperService.defaultCenter;
    }
  }
}
