import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICoordenadas, ICrearGateway, IGateway } from 'modelos/src';

import { HelperService } from '../../../auxiliares/helper.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GatewaysService } from '../gateways.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crear-editar-gateways',
  templateUrl: './crear-editar-gateways.component.html',
  styleUrls: ['./crear-editar-gateways.component.scss'],
})
export class CrearEditarGatewaysComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;
  public coordenadas?: ICoordenadas;
  public mapOptions: google.maps.MapOptions = HelperService.mapOptions;
  public markerOptions?: google.maps.MarkerOptions;
  public ubic = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGateway,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarGatewaysComponent>,
    private service: GatewaysService,
    private helper: HelperService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? `Editar Gateway` : `Crear Gateway`;
    this.form = this.fb.group({
      gatewayEui: this.data?.gatewayEui,
      datos: this.fb.group({
        nombre: this.data?.datos?.nombre,
        descripcion: this.data?.datos?.descripcion,
        credenciales: this.fb.group({
          usuario: this.data?.datos?.credenciales?.usuario,
          password: this.data?.datos?.credenciales?.password,
        }),
        notas: this.data?.datos?.notas,
      }),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICrearGateway = this.form?.value;
    data.ubicacion = this.coordenadas;
    return data;
  }

  private async initMap(): Promise<void> {
    this.coordenadas =
      this.data?.ubicacion || (await HelperService.getCurrentPosition());
    this.mapOptions.center = this.coordenadas;
    this.markerOptions = {
      draggable: true,
      position: this.coordenadas,
    };
  }

  public setLocation(event: google.maps.MapMouseEvent): void {
    this.coordenadas = event.latLng?.toJSON();
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        console.log(data);
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        console.log(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    this.initMap();
    this.loading = false;
  }
}
