import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../modulos/login/login.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteAdminGuard  {
  constructor(private router: Router, private helper: HelperService) {}

  canActivate() {
    const usuario = LoginService.getUsuario();
    const permisos = usuario?.permisos;
    const permisosAdmin = permisos?.filter(
      (permiso) => permiso.nivel === 'Admin'
    );
    if (permisosAdmin.length > 0) {
      return true;
    } else {
      this.helper.notifWarn('Permiso de nivel denegado');
      return this.router.parseUrl('/login');
    }
  }
}
