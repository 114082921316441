<div class="lista-container">
  <div style="width: 100%">
    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input
        type="search"
        autocomplete="off"
        matInput
        [ngModel]="searchText"
        (ngModelChange)="searchKey($event)"
      />
    </mat-form-field>
  </div>
  <div class="lista-content">
    <ng-container *ngIf="gateways">
      <div *ngFor="let g of filteredGatewayList">
        <div class="lista-item">
          <div class="lista-text" (click)="seleccionar(g)">
            <p class="lista-titulo">{{ g.name }}</p>
            <p
              [ngClass]="
                g.online ? 'lista-fecha online' : 'lista-fecha offline'
              "
            >
              {{ g.lastSeenAt | date : "short" }}
            </p>
            <p>{{ g.id }}</p>
          </div>
          <div class="lista-descripcion" (click)="seleccionar(g)">
            <p>{{ g.description }}</p>
          </div>
          <div class="lista-actions">
            <mat-icon
              color="accent"
              style="cursor: pointer"
              *ngIf="!checkCreado(g)"
              (click)="create(g)"
              >add</mat-icon
            >
            <mat-icon
              color="accent"
              style="cursor: pointer"
              *ngIf="checkCreado(g)"
              (click)="edit(g)"
              >edit</mat-icon
            >
            <mat-icon color="warn" style="cursor: pointer" (click)="clear()"
              >clear</mat-icon
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
