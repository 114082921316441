import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IConfigLora,
  ICreateTipoDispositivo,
  IDeviceProfileChirpstack,
  IDeviceProfileOrbiwise,
  IListado,
  ILoraServer,
  IQueryParam,
  ITipoDispositivo,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { tipoDispositivos } from '../../../../environments/datos';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ChirpstackService } from '../../chirpstack/chirpstack.service';
import { OrbiwiseService } from '../../orbiwise/chirpstack.service';
import { TipoDispositivosService } from '../tipo-dispositivos.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-tipo-dispositivos',
  templateUrl: './crear-editar-tipo-dispositivos.component.html',
  styleUrls: ['./crear-editar-tipo-dispositivos.component.scss'],
})
export class CrearEditarTipoDispositivosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  get configLoraServers() {
    return this.form?.get('loraServers') as UntypedFormArray;
  }
  public title?: string;

  public deviceProfiles: { idLoraServer: string; deviceProfiles: any[] }[] = [];

  public tipoDispositivos: TipoDispositivo[] = tipoDispositivos;
  public loraServers: ILoraServer[] = [];

  // Listado Continuo
  public loraServers$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITipoDispositivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarTipoDispositivosComponent>,
    private service: TipoDispositivosService,
    private chirpstackService: ChirpstackService,
    private orbiwiseService: OrbiwiseService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Tipo de dispositivo'
      : 'Crear Tipo de dispositivo';

    const formLoraServers: UntypedFormGroup[] = [];
    if (this.data?.loraServers?.length) {
      for (const loraServer of this.data?.loraServers) {
        formLoraServers.push(
          this.fb.group({
            idLoraServer: [loraServer.idLoraServer],
            deviceProfileID: [loraServer.deviceProfileID],
          })
        );
      }
    }

    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      integrationUrl: [this.data?.integrationUrl],
      loraServers: this.fb.array(formLoraServers),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateTipoDispositivo = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public async cambioLoraServer(i: number) {
    this.loading = true;
    const configLoraServer: IConfigLora = this.configLoraServers.at(i).value;
    const idLoraServer = configLoraServer.idLoraServer;
    const loraServer = this.loraServers.find((ls) => ls._id === idLoraServer);
    console.log(loraServer);

    if (loraServer?.tipo === 'ChirpStack') {
      this.configLoraServers.at(i).patchValue({ deviceProfileID: null });
      this.deviceProfiles[i] = {
        idLoraServer,
        deviceProfiles: await this.getDeviceProfilesChirpstack(loraServer),
      };
      console.log(
        'deviceProfiles Chirpstack',
        this.deviceProfiles[i].deviceProfiles
      );
    }
    if (loraServer?.tipo === 'Orbiwise') {
      this.configLoraServers.at(i).patchValue({ deviceProfileID: null });
      this.deviceProfiles[i] = {
        idLoraServer,
        deviceProfiles: await this.getDeviceProfilesOrbiwise(loraServer),
      };
      console.log(
        'deviceProfiles Orbiwise',
        this.deviceProfiles[i].deviceProfiles
      );
    }
    this.loading = false;
  }

  public agregarLoraServer() {
    this.configLoraServers.push(
      this.fb.group({
        idLoraServer: [null, Validators.required],
        deviceProfileID: [null, Validators.required],
      })
    );
  }

  public eliminarLoraServer(i: number) {
    this.configLoraServers.removeAt(i);
  }

  public labelDeviceProfile(i: number, dato: any): string | void {
    const idLoraServer = this.configLoraServers.at(i).value.idLoraServer;
    const loraServer = this.loraServers.find((ls) => ls._id === idLoraServer);
    if (loraServer?.tipo === 'ChirpStack') {
      const deviceProfile: IDeviceProfileChirpstack = dato;
      return deviceProfile.name;
    }
    if (loraServer?.tipo === 'Orbiwise') {
      const deviceProfile: IDeviceProfileOrbiwise = dato;
      return deviceProfile.profile_name;
    }
  }

  public valueDeviceProfile(i: number, dato: any): string | void {
    const idLoraServer = this.configLoraServers.at(i).value.idLoraServer;
    const loraServer = this.loraServers.find((ls) => ls._id === idLoraServer);
    if (loraServer?.tipo === 'ChirpStack') {
      const deviceProfile: IDeviceProfileChirpstack = dato;
      return deviceProfile.id;
    }
    if (loraServer?.tipo === 'Orbiwise') {
      const deviceProfile: IDeviceProfileOrbiwise = dato;
      return deviceProfile.profile_uuid;
    }
  }

  //

  private async listarLoraServers(): Promise<void> {
    const query: IQueryParam = {
      sort: 'nombre',
    };
    this.loraServers$?.unsubscribe();
    this.loraServers$ = this.listadosService
      .subscribe<IListado<ILoraServer>>('loraServers', query)
      .subscribe((data) => {
        this.loraServers = data.datos;
        console.log(`listado de loraServers`, data);
      });
    await this.listadosService.getLastValue('loraServers', query);
  }

  private async getDeviceProfilesChirpstack(
    loraServer: ILoraServer
  ): Promise<IDeviceProfileChirpstack[]> {
    if (
      loraServer?.url &&
      loraServer?.chirpstack?.token &&
      loraServer?.chirpstack?.organizationID
    ) {
      return await firstValueFrom(
        this.chirpstackService.getDeviceProfiles(
          loraServer.url,
          loraServer.chirpstack?.token,
          loraServer.chirpstack?.organizationID
        )
      );
    }
    return [];
  }

  private async getDeviceProfilesOrbiwise(
    loraServer: ILoraServer
  ): Promise<IDeviceProfileOrbiwise[]> {
    if (
      loraServer?.url &&
      loraServer?.orbiwise?.user &&
      loraServer?.orbiwise?.pass
    ) {
      return await firstValueFrom(
        this.orbiwiseService.getDeviceProfiles(
          loraServer.url,
          loraServer.orbiwise?.user,
          loraServer.orbiwise?.pass
        )
      );
    }
    return [];
  }

  private async initDeviceProfiles() {
    if (this.data?.loraServers?.length) {
      for (let i = 0; i < this.data.loraServers.length; i++) {
        const loraServer = this.loraServers.find(
          (ls) => ls._id === this.data.loraServers?.[i].idLoraServer
        );
        if (loraServer?.tipo === 'ChirpStack') {
          this.deviceProfiles[i] = {
            idLoraServer: loraServer._id,
            deviceProfiles: await this.getDeviceProfilesChirpstack(loraServer),
          };
        }
        if (loraServer?.tipo === 'Orbiwise') {
          this.deviceProfiles[i] = {
            idLoraServer: loraServer._id,
            deviceProfiles: await this.getDeviceProfilesOrbiwise(loraServer),
          };
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarLoraServers();
    await this.initDeviceProfiles();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.loraServers$?.unsubscribe();
  }
}
