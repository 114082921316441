<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Obtener Appkey
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 70vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Deveui -->
      <mat-form-field>
        <mat-label>DevEUI</mat-label>
        <input
          matInput
          placeholder="DevEUI"
          formControlName="deveui"
          required
        />
      </mat-form-field>

      <!-- Llave -->
      <mat-form-field>
        <mat-label>Llave</mat-label>
        <input matInput placeholder="Llave" formControlName="llave" required />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <ng-container *ngIf="appkey">
    <p style="margin: 2em 0">Appkey: {{ appkey }}</p>

    <mat-divider></mat-divider>
  </ng-container>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      OBTENER APPKEY
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
