import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IDispositivo } from 'modelos/src';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { DispositivosService } from '../dispositivos.service';

@Component({
  selector: 'app-detalles-dispositivo',
  templateUrl: './detalles-dispositivo.component.html',
  styleUrls: ['./detalles-dispositivo.component.scss'],
})
export class DetallesDispositivoComponent implements OnInit, OnDestroy {
  public loading = false;

  public id?: string | null = null;
  public dispositivo?: IDispositivo;

  // Listado Continuo
  public dispositivo$?: Subscription;
  public reportes$?: Subscription;

  constructor(
    public helper: HelperService,
    private route: ActivatedRoute,
    public service: DispositivosService,
    private listadosService: ListadosService
  ) {}

  // Listados
  private async listarDispositivo(): Promise<void> {
    this.dispositivo$?.unsubscribe();
    this.dispositivo$ = this.listadosService
      .subscribe<IDispositivo>('dispositivo', this.id!)
      .subscribe((data) => {
        console.log(
          new Date().toLocaleString(),
          `listado de dispositivo`,
          data
        );
        this.dispositivo = data;
      });
    await this.listadosService.getLastValue('dispositivo', this.id!);
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.loading = true;
      this.id = params.get('id');
      await this.listarDispositivo();
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.dispositivo$?.unsubscribe();
  }
}
