import { Component, Input, OnInit } from '@angular/core';
import { IResumenCliente } from 'modelos/src';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss'],
})
export class ResumenComponent {
  @Input() resumen?: IResumenCliente;
}
