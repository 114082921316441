import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListadoLotesDispositivosComponent } from './listado-lotes-dispositivos/listado-lotes-dispositivos.component';
import { CrearEditarLotesDispositivosComponent } from './crear-editar-lotes-dispositivos/crear-editar-lotes-dispositivos.component';
import { LotesDispositivosRoutingModule } from './lote-dispositivos.routing.module';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';

@NgModule({
  declarations: [
    ListadoLotesDispositivosComponent,
    CrearEditarLotesDispositivosComponent,
  ],
  imports: [CommonModule, LotesDispositivosRoutingModule, AuxiliaresModule],
})
export class LotesDispositivosModule {}
