<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 70vh; overflow: auto">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
    >
      <!-- Cliente -->
      <mat-form-field>
        <mat-label>Cliente</mat-label>
        <mat-select
          formControlName="idCliente"
          required
          (selectionChange)="cambioCliente()"
        >
          <mat-option *ngFor="let dato of clientes" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Deveui, DeviceName, Appkey - SOLO EN CREAR -->
      <app-textarea
        *ngIf="!data"
        #textarea
        [form]="form"
        [controlName]="'deveuis'"
        [ejemplo]="
          'Ej. 4750453454378c14 ; NCA-000001 ; 4750453454378c144750453454378c14'
        "
        [formato]="'DevEUI* ; DeviceName* ; Appkey'"
        [rows]="10"
      ></app-textarea>

      <!-- Deveui - SOLO EN EDITAR -->
      <mat-form-field *ngIf="data">
        <mat-label>DevEUI</mat-label>
        <input
          matInput
          placeholder="DevEUI"
          formControlName="deveui"
          required
        />
      </mat-form-field>

      <!-- DeviceName - SOLO EN EDITAR -->
      <mat-form-field *ngIf="data">
        <mat-label>Device Name</mat-label>
        <input
          matInput
          placeholder="Device Name"
          formControlName="deviceName"
          required
        />
      </mat-form-field>

      <!-- Lote -->
      <mat-form-field>
        <mat-label>Lote</mat-label>
        <mat-select formControlName="idLoteDispositivo">
          <mat-option *ngFor="let dato of loteDispositivos" [value]="dato._id">
            {{ dato.nombre }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="form?.get('idLoteDispositivo')?.value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="$event.stopPropagation(); form?.patchValue({ idLote: null })"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Appkey -->
      <mat-form-field>
        <mat-label>AppKey</mat-label>
        <input matInput placeholder="AppKey" formControlName="appkey" />
        <mat-hint>Opcional, si no se proporciona se deriva del lote</mat-hint>
      </mat-form-field>

      <!-- Tipo de Dispositivo -->
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <mat-select
          formControlName="idTipoDispositivo"
          required
          (selectionChange)="cambioTipoDispositivo()"
        >
          <ng-container *ngFor="let dato of tipoDispositivos">
            <mat-option
              *ngIf="tiposDispositivoPorCliente.includes(dato.nombre)"
              [value]="dato._id"
            >
              {{ dato.nombre }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <!-- Conectividad -->
      <mat-form-field>
        <mat-label>Conectividad</mat-label>
        <mat-select formControlName="conectividad" required>
          <mat-option *ngFor="let dato of conectividad" [value]="dato">
            {{ dato }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- SOLO CON CONECTIVIDAD LORA -->
      <ng-container *ngIf="form?.get('conectividad')?.value === 'LORA'">
        <!-- Lora Server -->
        <mat-form-field>
          <mat-label>Lora Server</mat-label>
          <mat-select
            formControlName="idLoraServer"
            (selectionChange)="cambioLoraServer()"
          >
            <mat-option *ngFor="let dato of loraServers" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Device Profile -->
        <mat-form-field>
          <mat-label>Device Profile</mat-label>
          <mat-select formControlName="deviceProfileID">
            <mat-option
              *ngFor="let dato of deviceProfiles"
              [value]="valueDeviceProfile(dato)"
            >
              {{ labelDeviceProfile(dato) }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Crear En Lora Server -->
        <mat-checkbox
          *ngIf="!data"
          [(ngModel)]="crearEnServer"
          [ngModelOptions]="{ standalone: true }"
          >Crear en Lora Server</mat-checkbox
        >
      </ng-container>
    </form>
  </mat-card-content>

  <p class="label-error">
    {{ form?.getError("error") }}
  </p>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
