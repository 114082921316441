import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  ILoteDispositivo,
  IUpdateLoteDispositivo,
  ICreateLoteDispositivo,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LoteDispositivosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<ILoteDispositivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/lotedispositivos`, { params });
  }

  public crear(dato: ICreateLoteDispositivo): Observable<ILoteDispositivo> {
    return this.http.post(`/lotedispositivos`, dato);
  }

  public listarPorId(id: string): Observable<ILoteDispositivo> {
    return this.http.get(`/lotedispositivos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateLoteDispositivo
  ): Observable<ILoteDispositivo> {
    return this.http.put(`/lotedispositivos/${id}`, dato);
  }

  public eliminar(id: string): Observable<ILoteDispositivo> {
    return this.http.delete(`/lotedispositivos/${id}`);
  }
}
