<mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortChange($event)"
>
  <!-- Columna fechaCreacion -->
  <ng-container matColumnDef="fechaCreacion">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Fecha de Creación
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.fechaCreacion | date: "short" }}
    </mat-cell>
  </ng-container>

  <!-- Columna timestamp -->
  <ng-container matColumnDef="timestamp">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Timestamp
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.timestamp | date: "short" }}
    </mat-cell>
  </ng-container>

  <!-- Columna fCnt -->
  <ng-container matColumnDef="fCnt">
    <mat-header-cell *matHeaderCellDef mat-sort-header> fCnt </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.fCnt }}
    </mat-cell>
  </ng-container>

  <!-- Columna puerto -->
  <ng-container matColumnDef="puerto">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Puerto
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.puerto }}
    </mat-cell>
  </ng-container>

  <!-- Columna payload -->
  <ng-container matColumnDef="payload">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Payload
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span style="overflow: auto">
        {{ row.payload }}
      </span>
    </mat-cell>
  </ng-container>

  <!-- Columna adr -->
  <ng-container matColumnDef="adr">
    <mat-header-cell *matHeaderCellDef mat-sort-header> ADR </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-icon *ngIf="!row.adr" [style.color]="'red'"> error </mat-icon>
      <mat-icon *ngIf="row.adr" [style.color]="'green'">
        check_circle
      </mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Columna dr -->
  <ng-container matColumnDef="dr">
    <mat-header-cell *matHeaderCellDef mat-sort-header> DR </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.dr }}
    </mat-cell>
  </ng-container>

  <!-- Columna rssi -->
  <ng-container matColumnDef="rssi">
    <mat-header-cell *matHeaderCellDef mat-sort-header> RSSI </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.rssi | number: "1.0-2" }}
    </mat-cell>
  </ng-container>

  <!-- Columna snr -->
  <ng-container matColumnDef="snr">
    <mat-header-cell *matHeaderCellDef mat-sort-header> SNR </mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.snr | number: "1.0-2" }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
</mat-table>
<mat-paginator
  [length]="totalCount"
  [pageSize]="helper.getPageSize(nombreTabla)"
  [pageSizeOptions]="helper.pageSizeOptions"
  [showFirstLastButtons]="true"
  (page)="pageEvent($event)"
>
</mat-paginator>
