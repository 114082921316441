<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="accent"
></mat-progress-bar>

<div class="container">
  <div class="banner">
    <img src="assets/imgs/logo.png" alt="horatech" style="width: 500px" />
  </div>
  <div class="tiles-container" *ngIf="tiles">
    <ng-container *ngFor="let t of tiles">
      <app-tile [tile]="t"></app-tile>
    </ng-container>
  </div>

  <div class="resumenes-container" *ngIf="resumenes">
    <div *ngFor="let resumen of resumenes" style="width: 25%">
      <app-resumen [resumen]="resumen"></app-resumen>
    </div>
  </div>
</div>
