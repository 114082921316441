<google-map width="100%" height="100%" [options]="options" [center]="center">
  <ng-container *ngFor="let g of gateways">
    <map-marker
      #marker="mapMarker"
      [position]="{
        lat: g.location?.latitude!,
        lng: g.location?.longitude!
      }"
      [options]="markerOptions"
      [icon]="g.icon!"
      (mapClick)="infoWindow.open(marker)"
    >
      <map-info-window #infoWindow="mapInfoWindow">
        <div class="info-window">
          <div class="info-titulo">
            <p>{{ g.name }}</p>
          </div>
          <div class="info-contenido">
            <div class="item-list">
              <p>DEVEUI</p>
              <p>{{ g.id }}</p>
            </div>
            <div class="item-list">
              <p>Estado</p>
              <mat-icon
                *ngIf="g.online"
                style="color: #32cd32"
                matTooltip="Online"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!g.online"
                style="color: #cd3232"
                matTooltip="Offline"
                >cancel</mat-icon
              >
            </div>
            <div class="item-list">
              <p>Última Conexión</p>
              <p>{{ g.lastSeenAt | date : "short" }}</p>
            </div>
            <div class="item-list">
              <p>Creado</p>
              <p>{{ g.createdAt | date : "short" }}</p>
            </div>
          </div>
        </div>
      </map-info-window></map-marker
    >
  </ng-container>
</google-map>
