import { Component, Input } from '@angular/core';

export interface ITile {
  title: string;
  content: string;
  color: string;
}

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  @Input() tile?: ITile;
}
