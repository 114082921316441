import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewaysComponent } from './gateways/gateways.component';
import { Gateways2RoutingModule } from './gateways-2.routing.module';
import { AuxiliaresModule } from 'src/app/auxiliares/auxiliares.module';
import { MapaComponent } from './mapa/mapa.component';
import { ListaComponent } from './lista/lista.component';

@NgModule({
  declarations: [GatewaysComponent, MapaComponent, ListaComponent],
  imports: [CommonModule, Gateways2RoutingModule, AuxiliaresModule],
})
export class Gateways2Module {}
