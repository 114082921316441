<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 70vh; overflow: auto">
    <form id="loginForm" [formGroup]="form!" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>Gateway Eui</mat-label>
        <input
          matInput
          formControlName="gatewayEui"
          autocomplete="doNotAutoComplete"
        />
      </mat-form-field>
      <br />
      <br />
      <div formGroupName="datos">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input
            matInput
            placeholder="GPE-8CH-77"
            formControlName="nombre"
            autocomplete="doNotAutoComplete"
          />
        </mat-form-field>
        <br />
        <br />
        <mat-form-field>
          <mat-label>Descripción</mat-label>
          <textarea matInput rows="3" formControlName="descripcion"></textarea>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field>
          <mat-label>Notas</mat-label>
          <textarea matInput rows="3" formControlName="notas"></textarea>
        </mat-form-field>
        <br />
        <br />
        <div formGroupName="credenciales">
          <mat-form-field>
            <mat-label>Usuario</mat-label>
            <input
              matInput
              formControlName="usuario"
              autocomplete="doNotAutoComplete"
            />
          </mat-form-field>
          <br />
          <br />
          <mat-form-field>
            <mat-label>Contraseña</mat-label>
            <input
              matInput
              formControlName="password"
              autocomplete="doNotAutoComplete"
            />
          </mat-form-field>
          <br />
          <br />
        </div>
      </div>

      <div *ngIf="mapOptions">
        <google-map width="100%" [options]="mapOptions">
          <map-marker
            [options]="markerOptions!"
            (mapDragend)="setLocation($event)"
          ></map-marker>
        </google-map>
      </div></form
  ></mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->

  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar> </mat-card-footer
></mat-card>
