import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  ILoraServer,
  IUpdateLoraServer,
  ICreateLoraServer,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LoraServersService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILoraServer>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/loraservers`, { params });
  }

  public crear(dato: ICreateLoraServer): Observable<ILoraServer> {
    return this.http.post(`/loraservers`, dato);
  }

  public listarPorId(id: string): Observable<ILoraServer> {
    return this.http.get(`/loraservers/${id}`);
  }

  public editar(id: string, dato: IUpdateLoraServer): Observable<ILoraServer> {
    return this.http.put(`/loraservers/${id}`, dato);
  }

  public eliminar(id: string): Observable<ILoraServer> {
    return this.http.delete(`/loraservers/${id}`);
  }
}
