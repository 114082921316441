import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {
  IListado,
  IUsuario,
  IQueryParam,
  ICliente,
  ISocketMessage,
  IDispositivo,
  ILoteDispositivo,
  ITipoDispositivo,
  ILoraServer,
  ILogUplink,
  IGateway,
} from 'modelos/src';
import { ClientesService } from '../modulos/clientes/clientes.service';
import { UsuariosService } from '../modulos/usuarios/usuarios.service';
import { WebSocketService } from './websocket';
import { DispositivosService } from '../modulos/dispositivos/dispositivos.service';
import { LoteDispositivosService } from '../modulos/lotes-dispositivos/lote-dispositivos.service';
import { TipoDispositivosService } from '../modulos/tipo-dispositivos/tipo-dispositivos.service';
import { LoraServersService } from '../modulos/lora-servers/lora-servers.service';
import { GatewaysService } from '../modulos/gateways/gateways.service';
import { AuditoriasService } from '../modulos/auditorias/auditorias.service';

type Entidad =
  | 'cliente'
  | 'clientes'
  | 'usuario'
  | 'usuarios'
  | 'dispositivo'
  | 'dispositivos'
  | 'loteDispositivo'
  | 'loteDispositivos'
  | 'tipoDispositivo'
  | 'tipoDispositivos'
  | 'loraServer'
  | 'loraServers'
  | 'logUplink'
  | 'logUplinks'
  | 'gateway'
  | 'gateways'
  | 'auditoria'
  | 'auditorias';

type Tipo =
  | ICliente
  | IListado<ICliente>
  | IUsuario
  | IListado<IUsuario>
  | IDispositivo
  | IListado<IDispositivo>
  | ILoteDispositivo
  | IListado<ILoteDispositivo>
  | ITipoDispositivo
  | IListado<ITipoDispositivo>
  | ILoraServer
  | IListado<ILoraServer>
  | ILogUplink
  | IListado<ILogUplink>
  | IGateway
  | IListado<IGateway>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryParam) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  cliente: IRequestId;
  clientes: IRequestQuery;
  usuario: IRequestId;
  usuarios: IRequestQuery;
  dispositivo: IRequestId;
  dispositivos: IRequestQuery;
  loteDispositivo: IRequestId;
  loteDispositivos: IRequestQuery;
  tipoDispositivo: IRequestId;
  tipoDispositivos: IRequestQuery;
  loraServer: IRequestId;
  loraServers: IRequestQuery;
  logUplink: IRequestId;
  logUplinks: IRequestQuery;
  gateway: IRequestId;
  gateways: IRequestQuery;
  auditoria: IRequestId;
  auditorias: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private webSocketService: WebSocketService,
    private clientesService: ClientesService,
    private usuariosService: UsuariosService,
    private dispositivosService: DispositivosService,
    private loteDispositivosService: LoteDispositivosService,
    private tipoDispositivosService: TipoDispositivosService,
    private loraServersService: LoraServersService,
    private gatewaysService: GatewaysService,
    private auditoriasService: AuditoriasService,
  ) {
    this.subscribeWsUpdates();
  }

  //

  private log() {
    setInterval(() => {
      for (const ent in this.entidades) {
        const entidad = this.entidades[ent as Entidad];
        for (const key in entidad.keys) {
          if (entidad.keys[key as string].subscribe.observers.length) {
            console.log(
              `Entidad ${ent} key ${key} requests ${entidad.keys[key as string].requests
              }. Subscribers ${entidad.keys[key as string].subscribe.observers.length
              }`
            );
          }
        }
      }
    }, 5000);
  }

  // Subscribe

  public subscribe<Tipo>(
    entidad: Entidad,
    query: IQueryParam | string
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
        // console.log(`Nueva key ${key}`);
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: Entidad,
    query: IQueryParam | string
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados Entidades
  private async listarCliente(id: string): Promise<ICliente> {
    const response = await this.clientesService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarClientes(
    query: IQueryParam
  ): Promise<IListado<ICliente>> {
    const response = await this.clientesService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await this.usuariosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuarios(
    query: IQueryParam
  ): Promise<IListado<IUsuario>> {
    const response = await this.usuariosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDispositivo(id: string): Promise<IDispositivo> {
    const response = await this.dispositivosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDispositivos(
    query: IQueryParam
  ): Promise<IListado<IDispositivo>> {
    const response = await this.dispositivosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLoteDispositivo(id: string): Promise<ILoteDispositivo> {
    const response = await this.loteDispositivosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLoteDispositivos(
    query: IQueryParam
  ): Promise<IListado<ILoteDispositivo>> {
    const response = await this.loteDispositivosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTipoDispositivo(id: string): Promise<ITipoDispositivo> {
    const response = await this.tipoDispositivosService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarTipoDispositivos(
    query: IQueryParam
  ): Promise<IListado<ITipoDispositivo>> {
    const response = await this.tipoDispositivosService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLoraServer(id: string): Promise<ILoraServer> {
    const response = await this.loraServersService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLoraServers(
    query: IQueryParam
  ): Promise<IListado<ILoraServer>> {
    const response = await this.loraServersService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogUplink(id: string): Promise<ILogUplink> {
    const response = await this.dispositivosService
      .listarLogUplinkPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLogUplinks(
    query: IQueryParam
  ): Promise<IListado<ILogUplink>> {
    const response = await this.dispositivosService
      .listarLogUplinks(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGateway(id: string): Promise<IGateway> {
    const response = await this.gatewaysService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarGateways(
    query: IQueryParam
  ): Promise<IListado<IGateway>> {
    const response = await this.gatewaysService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditoria(id: string): Promise<IUsuario> {
    const response = await this.auditoriasService
      .listarPorId(id)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  private async listarAuditorias(
    query: IQueryParam
  ): Promise<IListado<IUsuario>> {
    const response = await this.auditoriasService
      .listar(query)
      .pipe(take(1))
      .toPromise();
    return JSON.parse(JSON.stringify(response));
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      cliente: { fn: this.listarCliente.bind(this), keys: {} },
      clientes: { fn: this.listarClientes.bind(this), keys: {} },
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      dispositivo: { fn: this.listarDispositivo.bind(this), keys: {} },
      dispositivos: { fn: this.listarDispositivos.bind(this), keys: {} },
      loteDispositivo: { fn: this.listarLoteDispositivo.bind(this), keys: {} },
      loteDispositivos: {
        fn: this.listarLoteDispositivos.bind(this),
        keys: {},
      },
      tipoDispositivo: { fn: this.listarTipoDispositivo.bind(this), keys: {} },
      tipoDispositivos: {
        fn: this.listarTipoDispositivos.bind(this),
        keys: {},
      },
      loraServer: { fn: this.listarLoraServer.bind(this), keys: {} },
      loraServers: { fn: this.listarLoraServers.bind(this), keys: {} },
      logUplink: { fn: this.listarLogUplink.bind(this), keys: {} },
      logUplinks: { fn: this.listarLogUplinks.bind(this), keys: {} },
      gateway: { fn: this.listarGateway.bind(this), keys: {} },
      gateways: { fn: this.listarGateways.bind(this), keys: {} },
      auditoria: { fn: this.listarAuditoria.bind(this), keys: {} },
      auditorias: { fn: this.listarAuditorias.bind(this), keys: {} },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  // Actualizar Entidades
  private async actualizarQuery(entidad: Entidad): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (ent.keys.hasOwnProperty(key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          if (ent.keys[key].subscribe.observers.length) {
            ent.keys[key].requests++;
            while (ent.keys[key].requests) {
              ent.keys[key].cache = undefined;
              await this.listarQuery(entidad, query, ent.fn);
              ent.keys[key].requests--;
            }
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private async actualizarId(entidad: Entidad, id?: string): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              await this.listarId(entidad, id, ent.fn);
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: Entidad,
    query: IQueryParam,
    fn: (query: IQueryParam) => Promise<any>
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key].cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
      ent.keys[key].subscribe.next(response);
    } else {
      ent.keys[key].subscribe.next(ent.keys[key].cache!);
    }
  }

  private async listarId(
    entidad: Entidad,
    id: string,
    fn: (id: string) => Promise<any>
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
      ent.keys[id].subscribe.next(response);
    } else {
      ent.keys[id].subscribe.next(ent.keys[id].cache!);
    }
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('clientes') || message.path === 'clientes') {
      this.actualizarQuery('clientes');
      this.actualizarId('cliente', message.body?._id);
    }
    if (message.paths?.includes('usuarios') || message.path === 'usuarios') {
      this.actualizarQuery('usuarios');
      this.actualizarId('usuario', message.body?._id);
    }
    if (
      message.paths?.includes('dispositivos') ||
      message.path === 'dispositivos'
    ) {
      this.actualizarQuery('dispositivos');
      this.actualizarId('dispositivo', message.body?._id);
    }
    if (
      message.paths?.includes('lotedispositivos') ||
      message.path === 'lotedispositivos'
    ) {
      this.actualizarQuery('loteDispositivos');
      this.actualizarId('loteDispositivo', message.body?._id);
    }
    if (
      message.paths?.includes('tipodispositivos') ||
      message.path === 'tipodispositivos'
    ) {
      this.actualizarQuery('tipoDispositivos');
      this.actualizarId('tipoDispositivo', message.body?._id);
    }
    if (
      message.paths?.includes('loraservers') ||
      message.path === 'loraservers'
    ) {
      this.actualizarQuery('loraServers');
      this.actualizarId('loraServer', message.body?._id);
    }
    if (
      message.paths?.includes('loguplinks') ||
      message.path === 'loguplinks'
    ) {
      this.actualizarQuery('logUplinks');
      this.actualizarId('logUplink', message.body?._id);
    }
    if (message.paths?.includes('gateways') || message.path === 'gateways') {
      this.actualizarQuery('gateways');
      this.actualizarId('gateway', message.body?._id);
    }
    if (message.paths?.includes('auditorias') || message.path === 'auditorias') {
      this.actualizarQuery('auditorias');
      this.actualizarId('auditoria', message.body?._id);
    }
  }
}
