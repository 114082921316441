import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { IFilter, IQueryParam, IListado, IGateway } from 'modelos/src';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';
import { IRegExpSearch } from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { CrearEditarGatewaysComponent } from '../crear-editar-gateways/crear-editar-gateways.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GatewaysService } from '../gateways.service';

@Component({
  selector: 'app-listado-gateways',
  templateUrl: './listado-gateways.component.html',
  styleUrls: ['./listado-gateways.component.scss'],
})
export class ListadoGatewaysComponent implements OnInit {
  public loading = true;
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // TABLA
  public dataSource = new MatTableDataSource<IGateway>();
  public totalCount = 0;
  public columnas: string[] = ['gatewayEui', 'acciones'];
  public nombreColumnas: string[] = ['GatewayEui', 'Acciones'];
  public nombreTabla = 'admin-agro-v2-gateways';
  // Filtros Tabla

  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
  };

  // Listado Continuo
  public gateways$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: GatewaysService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarGatewaysComponent, config);
  }

  public async editar(data: IGateway) {
    const config: MatDialogConfig = {
      data,
      width: '700px',

      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarGatewaysComponent, config);
  }

  public async eliminar(dato: IGateway): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar Lora Server ${dato._id}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.gateways$?.unsubscribe();
    this.gateways$ = this.listadosService
      .subscribe<IListado<IGateway>>('gateways', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de gateways`, data);
      });
    await this.listadosService.getLastValue('gateways', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.gateways$?.unsubscribe();
  }
}
