import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IUsuario,
  ICreateUsuario,
  IUpdateUsuario,
  ICliente,
  Rol,
  IQueryParam,
  IListado,
  NivelPermiso,
  TipoDispositivo,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crear-editar-usuarios',
  templateUrl: './crear-editar-usuarios.component.html',
  styleUrls: ['./crear-editar-usuarios.component.scss'],
})
export class CrearEditarUsuariosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;

  public clientes: ICliente[] = [];
  public roles: Rol[] = ['Admin', 'Lectura', 'Escritura', 'Tecnico'];
  public niveles: NivelPermiso[] = ['Admin', 'Cliente', 'Establecimiento'];
  public tipos: TipoDispositivo[] = [];

  get formArrayPermisos() {
    return this.form?.get('permisos') as UntypedFormArray;
  }

  // Listado Continuo
  public clientes$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuariosComponent>,
    private service: UsuariosService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';

    const formPermisos: UntypedFormGroup[] = [];

    if (this.data?.permisos?.length) {
      for (const permiso of this.data?.permisos) {
        formPermisos.push(
          this.fb.group({
            nivel: [permiso.nivel, Validators.required],
            rol: [permiso.rol, Validators.required],
            idCliente: [permiso.idCliente],
            idEstablecimiento: [permiso.idEstablecimiento],
            tiposDispositivo: [permiso.tiposDispositivo],
          })
        );
      }
    }

    this.form = this.fb.group({
      username: [this.data?.username, Validators.required],
      password: ['', !this.data ? Validators.required : null],
      permisos: this.fb.array(formPermisos),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateUsuario = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateUsuario = this.form?.value;
    // Password
    delete data.password;
    const password = this.form?.get('password')?.value;
    if (password) {
      data.password = password;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await firstValueFrom(this.service.editar(this.data._id, data));

        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public agregarPermiso() {
    this.formArrayPermisos.push(
      this.fb.group({
        nivel: [null, Validators.required],
        rol: [null, Validators.required],
        idCliente: [],
        idEstablecimiento: [],
        tiposDispositivo: [null, Validators.required],
      })
    );
  }

  public eliminarPermiso(i: number) {
    this.formArrayPermisos.removeAt(i);
  }

  public changeCliente(e: string) {
    const cliente = this.clientes.find((c) => c._id === e);
    this.tipos = cliente?.tiposDispositivo || [];
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre tiposDispositivo',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarClientes();
    this.changeCliente(
      this.formArrayPermisos.controls[0]?.get('idCliente')?.value
    );
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
  }
}
