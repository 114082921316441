import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  IGateway,
  ICrearGateway,
  IUpdateGateway,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class GatewaysService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IGateway>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/gateways`, { params });
  }

  public crear(dato: ICrearGateway): Observable<IGateway> {
    return this.http.post(`/gateways`, dato);
  }

  public listarPorId(id: string): Observable<IGateway> {
    return this.http.get(`/gateways/${id}`);
  }

  public editar(id: string, dato: IUpdateGateway): Observable<IGateway> {
    return this.http.put(`/gateways/${id}`, dato);
  }

  public eliminar(id: string): Observable<IGateway> {
    return this.http.delete(`/gateways/${id}`);
  }
}
