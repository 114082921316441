import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LandingRoutingModule } from './landing.routing.module';
import { TileComponent } from './tile/tile.component';
import { ResumenComponent } from './resumen/resumen.component';

@NgModule({
  declarations: [LandingComponent, TileComponent, ResumenComponent],
  imports: [CommonModule, AuxiliaresModule, LandingRoutingModule],
})
export class LandingModule {}
