import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../auxiliares/http.service';
import { IResumen } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class ResumenService {
  constructor(private http: HttpService) {}

  public getResumen(): Observable<IResumen> {
    return this.http.get(`/resumens`);
  }
}
