import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { GatewaysRoutingModule } from './gateways.routing.module';
import { CrearEditarGatewaysComponent } from './crear-editar-gateways/crear-editar-gateways.component';
import { ListadoGatewaysComponent } from './listado-gateways/listado-gateways.component';

@NgModule({
  declarations: [ListadoGatewaysComponent, CrearEditarGatewaysComponent],
  imports: [CommonModule, GatewaysRoutingModule, AuxiliaresModule],
})
export class GatewaysModule {}
