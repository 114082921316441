import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ICoordenadas, IQueryParam } from 'modelos/src';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public pageSizeOptions = [5, 10, 15, 25, 50, 100, 500];
  public pageSizeOptions2 = [6, 12, 24, 48, 96, 192, 384];
  static defaultCenter = { lat: -35.5836812, lng: -58.0128784 };

  public static readonly mapOptions: google.maps.MapOptions = {
    zoom: 8,
    streetViewControl: false,
    fullscreenControl: false,
    rotateControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeId: 'satellite',
    mapTypeControl: false,
    // mapTypeControlOptions: {
    //   mapTypeIds: ['satellite', 'roadmap', 'hybrid', 'terrain'],
    //   style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
    //   position: google.maps.ControlPosition.TOP_LEFT,
    // },
    styles: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c8c8c8' }],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c8c8c8' }],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      // {
      //   featureType: "poi",
      //   elementType: "labels.text.fill",
      //   stylers: [{ color: "#d59563" }],
      // },
      // {
      //   featureType: 'poi.park',
      //   elementType: 'geometry',
      //   stylers: [{ color: '#263c3f' }],
      // },
      // {
      //   featureType: 'poi.park',
      //   elementType: 'labels.text.fill',
      //   stylers: [{ color: '#6b9a76' }],
      // },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      // {
      //   featureType: 'transit',
      //   elementType: 'geometry',
      //   stylers: [{ color: '#2f3948' }],
      // },
      // {
      //   featureType: 'transit.station',
      //   elementType: 'labels.text.fill',
      //   stylers: [{ color: '#d59563' }],
      // },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],
  };

  constructor(private snackBar: MatSnackBar) {}

  public getPageSize(nombreTabla?: string): number {
    const size = localStorage.getItem(nombreTabla || 'pageSize');
    return size ? +size : this.pageSizeOptions[4];
  }

  public setPageSize($event: PageEvent, nombreTabla?: string): void {
    localStorage.setItem(nombreTabla || 'pageSize', `${$event.pageSize}`);
  }

  public static getQueryParams(queryParams?: IQueryParam) {
    let params = new HttpParams();
    if (queryParams) {
      const keysIgnorar = [
        'page',
        'limit',
        'sort',
        'populate',
        'select',
        'filter',
      ];
      if (queryParams?.page) {
        params = params.set('page', queryParams.page.toString());
      }
      if (queryParams?.limit) {
        params = params.set('limit', queryParams.limit.toString());
      }
      if (queryParams?.sort) {
        params = params.set('sort', queryParams.sort);
      }
      if (queryParams?.populate) {
        params = params.set('populate', queryParams.populate);
      }
      if (queryParams?.select) {
        params = params.set('select', queryParams.select);
      }
      if (queryParams?.filter) {
        params = params.set('filter', queryParams.filter);
      }

      for (const key in queryParams) {
        if (!keysIgnorar.includes(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return params;
  }

  // Mapa
  public static async getCurrentPosition(): Promise<ICoordenadas> {
    return new Promise(async (resolve, reject) => {
      const ubicacionBase: ICoordenadas = {
        lat: -35.5836812,
        lng: -58.0128784,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion: ICoordenadas = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            resolve(ubicacion);
          },
          (error) => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          }
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  // Notificaciones
  public notifError(error: any): void {
    let errorMessage = error.error?.message;
    if (!errorMessage) {
      const typeofError = typeof error.error;
      if (typeofError === 'string') {
        errorMessage = error.error;
      } else if (typeofError === 'object') {
        errorMessage = JSON.stringify(error.error);
      }
    }
    console.error(errorMessage);
    this.snackBar.open(errorMessage, 'Cerrar', {
      panelClass: ['red-snackbar'],
    });
  }
  public notifSuccess(mensaje: string): void {
    this.snackBar.open(mensaje, 'Cerrar', {
      duration: 3000,
      panelClass: ['green-snackbar'],
    });
  }
  public notifWarn(mensaje: string): void {
    this.snackBar.open(mensaje, 'Cerrar', { panelClass: ['yellow-snackbar'] });
  }

  static stringToColor(s: string) {
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
      // tslint:disable-next-line: no-bitwise
      hash = s.charCodeAt(i) + ((hash << 5) - hash);
    }
    // tslint:disable-next-line: no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  static checkOnline(f?: string): boolean {
    if (!f) return false;
    const twoMins = 120000;
    const today = new Date();
    const fecha = new Date(f);
    const diff = today.getTime() - fecha.getTime();
    return diff < twoMins;
  }
}
