import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { ColumnSelectService } from './column-select.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColumnSelectComponent } from './column-select.component';

@NgModule({
  declarations: [
    ColumnSelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    DragDropModule
  ],
  providers: [
    ColumnSelectService
  ],
  exports: [
    ColumnSelectComponent
  ]
})
export class ColumnSelectModule { }
