<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="accent"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[estado]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="nombreTabla"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Cliente -->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Activo -->
    <ng-container matColumnDef="activo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Activo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.activo" [style.color]="'green'">check</mat-icon>
        <mat-icon *ngIf="!row.activo" [style.color]="'red'">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna Logo -->
    <ng-container matColumnDef="logo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Logo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <img
          *ngIf="row.imagenes?.logo"
          [src]="row.imagenes?.logo"
          [alt]="row.nombre"
          style="width: 100px"
        />
      </mat-cell>
    </ng-container>

    <!-- Columna tiposDispositivos -->
    <ng-container matColumnDef="tiposDispositivo">
      <mat-header-cell *matHeaderCellDef>
        Tipos de Dispositivo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngFor="let tipo of row.tiposDispositivo">
            <span>{{ tipo }}</span>
            <br />
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.getPageSize(nombreTabla)"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
