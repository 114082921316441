<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="accent"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <mat-checkbox
    style="padding: 1em"
    [(ngModel)]="usarColores"
    (change)="cambioUsarColores($event)"
    >Etiquetas de colores</mat-checkbox
  >
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[tipoDispositivo, conectividad, cliente]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="nombreTabla"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button
      mat-fab
      matTooltip="Obtener Appkey"
      color="primary"
      (click)="appkey()"
    >
      <mat-icon>key</mat-icon>
    </button>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Cliente -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.cliente?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna DevEUI -->
    <ng-container matColumnDef="deveui">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        DevEUI
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.deveui }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Device Name -->
    <ng-container matColumnDef="deviceName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Device Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="link" [routerLink]="row._id">{{ row.deviceName }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Appkey -->
    <ng-container matColumnDef="appkey">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Appkey
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.appkey }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Tipo -->
    <ng-container matColumnDef="tipoDispositivo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Tipo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span
          [attr.data]="row.tipoDispositivo?.nombre"
          [attr.usarColores]="usarColores"
        >
          {{ row.tipoDispositivo?.nombre }}
        </span>
      </mat-cell>
    </ng-container>

    <!-- Columna Conectividad -->
    <ng-container matColumnDef="conectividad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Conectividad
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <p style="margin: 0">{{ row.conectividad }}</p>
          <p style="margin: 0">{{ row.loraServer?.nombre }}</p>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Lote -->
    <ng-container matColumnDef="lote">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Lote
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.loteDispositivo?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Última Comunicación -->
    <ng-container matColumnDef="fechaUltimaComunicacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Última Comunicación
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.fechaUltimaComunicacion | date: "short" }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Lora Server -->
    <ng-container matColumnDef="loraServer">
      <mat-header-cell *matHeaderCellDef> Lora Server </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <button
          mat-mini-fab
          matTooltip="Buscar en Lora Server"
          color="primary"
          (click)="getFromLoraServer(row)"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          mat-mini-fab
          matTooltip="Crear en Lora Server"
          color="accent"
          (click)="createOnLoraServer(row)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar de Lora Server"
          (click)="deleteFromLoraServer(row)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <!-- <span class="mobile-label">Acciones:</span> -->
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.getPageSize(nombreTabla)"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
