import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListado, ILogUplink, IQueryParam } from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LogUplinksService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ILogUplink>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logUplinks`, { params });
  }

  public listarPorId(id: string): Observable<ILogUplink> {
    return this.http.get(`/logUplinks/${id}`);
  }

  public eliminar(
    id: string,
    query?: { eliminarEnServer: boolean }
  ): Observable<ILogUplink> {
    let params = HelperService.getQueryParams(query);
    return this.http.delete(`/dispositivos/${id}`, { params });
  }
}
