import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IListado,
  IQueryParam,
  ITipoDispositivo,
  IUpdateTipoDispositivo,
  ICreateTipoDispositivo,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class TipoDispositivosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<ITipoDispositivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/tipodispositivos`, { params });
  }

  public crear(dato: ICreateTipoDispositivo): Observable<ITipoDispositivo> {
    return this.http.post(`/tipodispositivos`, dato);
  }

  public listarPorId(id: string): Observable<ITipoDispositivo> {
    return this.http.get(`/tipodispositivos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdateTipoDispositivo
  ): Observable<ITipoDispositivo> {
    return this.http.put(`/tipodispositivos/${id}`, dato);
  }

  public eliminar(id: string): Observable<ITipoDispositivo> {
    return this.http.delete(`/tipodispositivos/${id}`);
  }
}
