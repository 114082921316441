import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IDispositivo,
  ICreateDispositivo,
  IListado,
  IQueryParam,
  IUpdateDispositivo,
  IGetDeviceChirpstack,
  ILogUplink,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class DispositivosService {
  constructor(private http: HttpService) { }

  public listar(queryParams?: IQueryParam): Observable<IListado<IDispositivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/dispositivos`, { params });
  }

  public crear(
    dato: ICreateDispositivo,
    query?: { crearEnServer: boolean }
  ): Observable<IDispositivo> {
    let params = HelperService.getQueryParams(query);
    return this.http.post(`/dispositivos`, dato, { params });
  }

  public editar2(
    id: string,
    dato: IUpdateDispositivo
  ): Observable<IDispositivo> {
    return this.http.post(`/dispositivos/${id}`, dato);
  }

  public listarPorId(id: string): Observable<IDispositivo> {
    return this.http.get(`/dispositivos/${id}`);
  }

  // public editar(
  //   id: string,
  //   dato: IUpdateDispositivo
  // ): Observable<IDispositivo> {
  //   return this.http.put(`/dispositivos/${id}`, dato);
  // }

  public eliminar(
    id: string,
    query?: { eliminarEnServer: boolean }
  ): Observable<void> {
    let params = HelperService.getQueryParams(query);
    return this.http.delete(`/dispositivos/${id}`, { params });
  }

  // LORA SERVER

  public getFromLoraServer(id: string): Observable<IGetDeviceChirpstack | any> {
    return this.http.get(`/dispositivos/loraserver/${id}`);
  }

  public deleteFromLoraServer(id: string): Observable<void> {
    return this.http.delete(`/dispositivos/loraserver/${id}`);
  }

  public createOnLoraServer(id: string): Observable<void> {
    return this.http.post(`/dispositivos/loraserver/${id}`, {});
  }

  public appkey(deveui: string, llave: string): Observable<{ appkey: string }> {
    return this.http.get(`/dispositivos/appkey/${deveui}/${llave}`, {});
  }

  //

  public listarLogUplinks(
    queryParams?: IQueryParam
  ): Observable<IListado<ILogUplink>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/logUplinks`, { params });
  }

  public listarLogUplinkPorId(id: string): Observable<ILogUplink> {
    return this.http.get(`/logUplinks/${id}`);
  }
  public eliminarLogUplink(
    id: string,
    query?: { eliminarEnServer: boolean }
  ): Observable<ILogUplink> {
    let params = HelperService.getQueryParams(query);
    return this.http.delete(`/logUplinks/${id}`, { params });
  }
}
